<template>
    <template v-if="company">
        <a
            class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
            @click="$emit('close')"
        >
            <span class="text-white mr-2">Закрыть</span>
            <svg class="fill-white w-4 h-4">
                <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
        </a>

        <div class="h-full overflow-y-auto">
            <div class="p-5 v-bbgray">
                <a class="flex items-center group cursor-pointer">
                    <div class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4 text-white text-lg">
                        <span>{{ company.name?.slice(0, 1) }}</span>
                    </div>
                    <div class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300">
                        {{ company.name }}
                    </div>
                </a>
            </div>

            <div class="flex h-full max-l:flex-col">
                <div class="flex-1">
                    <div class="px-5 py-2 v-bbgray flex flex-wrap">
                        <template v-if="!company.firm">
                            <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                <label class="text-gray-400">Юр/Физ лицо</label>
                                <select
                                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                    v-model="company.firm"
                                    @change="saveCompany(company)"
                                >
                                    <option value="1">Юр. лицо</option>
                                    <option value="2">Физ. лицо</option>
                                    <option value="3">ИП</option>
                                </select>
                            </div>
                        </template>

                        <template v-if="company.firm == 1 || company.firm == 3">
                            <div class="min-w-[160px] w-2/3 flex flex-col my-2 px-4">
                                <label class="text-gray-400">Короткое имя</label>
                                <input
                                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                    type="text"
                                    name="name"
                                    v-model="company.name"
                                    @change="saveCompany(company)"
                                />
                            </div>
                            <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                <label class="text-gray-400">Юр/Физ лицо</label>
                                <select
                                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                    v-model="company.firm"
                                    @change="saveCompany(company)"
                                >
                                    <option value="1">Юр. лицо</option>
                                    <option value="2">Физ. лицо</option>
                                    <option value="3">ИП</option>
                                </select>
                            </div>
                            <div class="w-full flex flex-col px-4 my-2">
                                <label>
                                    <span class="text-gray-400">Полное наименование</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        name="full"
                                        v-model="company.full"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                        </template>

                        <template v-if="company.firm == 2">
                            <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                <label class="text-gray-400">Имя</label>
                                <input
                                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                    type="text"
                                    name="name"
                                    v-model="company.name"
                                    @change="saveCompany(company)"
                                />
                            </div>
                            <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                <label>
                                    <span class="text-gray-400">Фамилия</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        name="full"
                                        v-model="company.full"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                            <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                <label class="text-gray-400">Юр/Физ лицо</label>
                                <select
                                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                    v-model="company.firm"
                                    @change="saveCompany(company)"
                                >
                                    <option value="1">Юр. лицо</option>
                                    <option value="2">Физ. лицо</option>
                                    <option value="3">ИП</option>
                                </select>
                            </div>
                        </template>

                        <template v-if="company.firm == 1 || company.firm == 3">
                            <div class="w-full flex flex-col px-4 my-2">
                                <label>
                                    <span class="text-gray-400">Юр адрес</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.uraddress"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                            <div class="w-full flex flex-col px-4 my-2">
                                <label>
                                    <span class="text-gray-400">ФИО директора для договора в родительном падеже</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.director"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                            <div class="w-full flex flex-col px-4 my-2">
                                <label>
                                    <span class="text-gray-400">«Основание» для договора в родительном падеже</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.osnovanie"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                        </template>
                    </div>

                    <div class="px-5 py-2 v-bbgray flex flex-wrap">
                        <template v-if="company.firm == 2 || company.firm == 3">
                            <template v-if="company.firm == 3">
                                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                    <label>
                                        <span class="text-gray-400">Фамилия</span>
                                        <input
                                            class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                            type="text"
                                            v-model="company.secondname"
                                            @change="saveCompany(company)"
                                        />
                                    </label>
                                </div>
                                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                    <label>
                                        <span class="text-gray-400">Имя</span>
                                        <input
                                            class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                            type="text"
                                            v-model="company.name2"
                                            @change="saveCompany(company)"
                                        />
                                    </label>
                                </div>
                                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                    <label>
                                        <span class="text-gray-400">Отчество</span>
                                        <input
                                            class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                            type="text"
                                            v-model="company.middlename"
                                            @change="saveCompany(company)"
                                        />
                                    </label>
                                </div>
                                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                    <label class="text-gray-400">Пол</label>
                                    <select
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        v-model="company.sex"
                                        @change="saveCompany(company)"
                                    >
                                        <option disabled value="">Выбрать</option>
                                        <option :value="'М'">М</option>
                                        <option :value="'Ж'">Ж</option>
                                    </select>
                                </div>
                            </template>
                            <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                <label>
                                    <span class="text-gray-400">Дата рождения</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.birthday"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                            <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                <label>
                                    <span class="text-gray-400">СНИЛС</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        name="snils"
                                        v-model="company.snils"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                            <template v-if="company.firm == 2">
                                <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                    <label>
                                        <span class="text-gray-400">ИНН</span>
                                        <input
                                            class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                            type="text"
                                            v-model="company.inn"
                                            @change="saveCompany(company)"
                                        />
                                    </label>
                                </div>
                            </template>
                        </template>

                        <template v-if="company.firm == 1 || company.firm == 3">
                            <div class="w-full flex flex-col px-4 my-2">
                                <label>
                                    <span class="text-gray-400">Почтовый адрес</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.address"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                        </template>

                        <template v-if="company.firm == 1 || company.firm == 3">
                            <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                                <label>
                                    <span class="text-gray-400">ИНН</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.inn"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                        </template>

                        <template v-if="company.firm == 1">
                            <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4" id="kpp">
                                <label>
                                    <span class="text-gray-400">КПП</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.kpp"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                            <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4" id="okpo">
                                <label>
                                    <span class="text-gray-400">ОКПО</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.okpo"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                        </template>
                        <template v-if="company.firm == 1 || company.firm == 3">
                            <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                                <label>
                                    <span v-if="company.firm == 1" class="text-gray-400">ОГРН</span>
                                    <span v-if="company.firm == 3" class="text-gray-400">ОГРНИП</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        name="ogrn"
                                        v-model="company.ogrn"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                        </template>
                    </div>

                    <template v-if="company.firm == 2">
                        <div class="px-5 py-2 flex flex-wrap">
                            <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                <label>
                                    <span class="text-gray-400">Серия паспорта</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.passserial"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                            <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                <label>
                                    <span class="text-gray-400">Номер паспорта</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.passnum"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                            <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                <label>
                                    <span class="text-gray-400">Дата выдачи</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="date"
                                        v-model="company.passdate"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                            <div class="clear"></div>
                            <div class="min-w-[160px] w-2/3 flex flex-col my-2 px-4">
                                <label>
                                    <span class="text-gray-400">Кем выдан</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.passwho"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                            <div class="min-w-[160px] w-1/3 flex flex-col my-2 px-4">
                                <label>
                                    <span class="text-gray-400">Код подразделения</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.passcode"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                            <div class="clear"></div>
                            <div class="min-w-[160px] w-2/3 flex flex-col my-2 px-4">
                                <label>
                                    <span class="text-gray-400">Адрес прописки или почтовый адрес</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="company.address"
                                        @change="saveCompany(company)"
                                    />
                                </label>
                            </div>
                        </div>
                    </template>

                    <div class="p-5 border-t">
                        <h3 class="font-latobold text-base ml-4">Банковские реквизиты</h3>
                        <div v-for="b in company.banks" :key="b">
                            <div class="flex mt-3 mb-1">
                                <a class="flex items-center ml-auto group cursor-pointer" @click="delBank(b)" bank="{{ b.id }}">
                                    <span class="text-green-500 group-hover:text-green-400 transition-colors duration-150">Удалить реквизиты</span>
                                    <svg class="ml-2 fill-gray-400 w-4 h-4 cursor-pointer group-hover:fill-rose-500 transition-colors duration-150">
                                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                                    </svg>
                                </a>
                            </div>

                            <div class="flex flex-wrap">
                                <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                                    <label>
                                        <span class="text-gray-500">Бик</span>
                                        <input
                                            class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                            type="text"
                                            v-model="b.bik"
                                            @change="saveBank(b)"
                                        />
                                    </label>
                                </div>
                                <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                                    <label>
                                        <span class="text-gray-500">Название банка</span>
                                        <input
                                            class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                            type="text"
                                            v-model="b.bankname"
                                            @change="saveBank(b)"
                                        />
                                    </label>
                                </div>
                                <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                                    <label>
                                        <span class="text-gray-500">Р/С</span>
                                        <input
                                            class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                            type="text"
                                            v-model="b.rs"
                                            @change="saveBank(b)"
                                        />
                                    </label>
                                </div>
                                <div class="min-w-[160px] w-1/2 flex flex-col my-2 px-4">
                                    <label>
                                        <span class="text-gray-500">К/С</span>
                                        <input
                                            class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                            type="text"
                                            v-model="b.ks"
                                            @change="saveBank(b)"
                                        />
                                    </label>
                                </div>
                            </div>

                            <div class="fgrp cox100">
                                <div class="hr"></div>
                            </div>
                        </div>
                        <button class="v-btn bg-green-500 hover:bg-green-400 mt-2 flex justify-center items-center" @click="addBank(company.id)">
                            <svg class="fill-white w-16px h-16px mr-3 rotate-45">
                                <use xlink:href="@/assets/i.svg#ico-no"></use>
                            </svg>
                            <span class="v-btn_text">Добавить реквизиты</span>
                        </button>
                    </div>
                </div>

                <div class="max-w-[300px] w-full p-5">
                    <div class="">
                        <div class="flex flex-wrap">
                            <template v-for="i in company.images" :key="i">
                                <div class="relative m-2 w-16">
                                    <a class="absolute -right-2 -top-2 group" v-if="profile.uid == i.uid" @click="delImg({ img: i, item: company })">
                                        <svg class="bg-white rounded-full w-4 h-4 group-hover:fill-rose-500 transition-colors duration-150">
                                            <use xlink:href="@/assets/i.svg#ico-no"></use>
                                        </svg>
                                    </a>
                                    <IcoImg :type="i.type" :img="i.img" :status="i.status" />
                                    <!--<pre>{{i.type}}</pre>-->
                                </div>
                            </template>

                            <template v-for="i in company.docs" :key="i">
                                <div class="relative m-2 w-16">
                                    <a class="absolute -right-2 -top-2 group" v-if="profile.uid == i.uid" @click="delDoc({ img: i, item: company })">
                                        <svg class="bg-white rounded-full w-5 h-5 group-hover:fill-rose-500 transition-colors duration-150">
                                            <use xlink:href="@/assets/i.svg#ico-no"></use>
                                        </svg>
                                    </a>
                                    <a :href="'https://api.tahocrm.ru/uploads/client/orig/' + i.file" download>
                                        <img src="https://api.tahocrm.ru/images/pdf-file.png" />
                                        <p>{{ i.name }}</p>
                                    </a>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div>
                        <div v-if="company.firm == 1" class="py-1">
                            <UploadImg :item="company" :id="company.id" type="company" status="22" name="Выписка ЕГРЮЛ: jpg, png" />
                        </div>
                        <div v-if="company.firm == 1" class="py-1">
                            <UploadImg :item="company" :id="company.id" type="company" status="23" name="ОГРН: jpg, png" />
                        </div>
                        <div v-if="company.firm == 3" class="py-1">
                            <UploadImg :item="company" :id="company.id" type="company" status="23" name="ОГРНИП: jpg, png" />
                        </div>
                        <div v-if="company.firm == 1 || company.firm == 3" class="py-1">
                            <UploadImg :item="company" :id="company.id" type="company" status="24" name="ИНН: jpg, png" />
                        </div>
                        <div class="py-1">
                            <UploadImg :item="company" :id="company.id" type="company" status="101" name="Согласие: jpg, png, pdf" />
                        </div>
                        <!-- <div class="py-1">
                            <UploadImg :item="company" :id="company.id" type="company" status="25" :name="imgName[25]" />
                        </div>
                        <div class="py-1">
                            <UploadImg :item="company" :id="company.id" type="company" status="26" :name="imgName[26]" />
                        </div>
                        <div class="py-1">
                            <UploadImg :item="company" :id="company.id" type="company" status="27" :name="imgName[27]" />
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </template>

    <div v-else class="loader opacity1 mt30">
        <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UploadImg from "../ui/UploadImg.vue";
//import API from "../../services/api";
export default {
    name: "CompanyEdit",

    components: { UploadImg },

    emits: ["close"],

    data() {
        return {
            loadForm: true,
            imgName: {
                22: "Выписка ЕГРЮЛ",
                23: "ОГРН",
                24: "ИНН",
                25: "СНИЛС",
                26: "Паспорт основной",
                27: "Паспорт прописка",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "client", "company", "activ"]),
    },

    methods: {
        ...mapActions(["saveCompany", "delImg", "addBank", "saveBank", "delBank", "showActivById", "setClient"]),

    },

    mounted() {
        window.DEBUG_CLIENT_COMPANY_EDIT = this;
    },
};
</script>
