<template>
    <div class="w-12 h-12 relative">
        <span v-if="order.status == 1 && order.act < 1" class="avatar w-full h-full flex items-center justify-center" :class="'bg-status-1'">
            <span class="w-5 h-5 flex items-center justify-center">
                <svg class="w-full h-full fill-white">
                    <use xlink:href="@/assets/i.svg#status-1"></use>
                </svg>
            </span>
        </span>

        <span v-if="order.status == 3" class="avatar w-full h-full flex items-center justify-center" :class="'bg-status-3'">
            <span class="w-5 h-5 flex items-center justify-center">
                <svg class="w-full h-full fill-white">
                    <use xlink:href="@/assets/i.svg#status-3"></use>
                </svg>
            </span>
        </span>

        <span v-if="order.status == 4" class="avatar w-full h-full flex items-center justify-center" :class="'bg-status-4'">
            <span class="w-5 h-5 flex items-center justify-center">
                <svg class="w-full h-full fill-white">
                    <use xlink:href="@/assets/i.svg#status-4"></use>
                </svg>
            </span>
        </span>

        <span v-if="order.status == 5" class="avatar w-full h-full flex items-center justify-center" :class="'bg-status-5'">
            <span class="w-5 h-5 flex items-center justify-center">
                <svg class="w-full h-full fill-white">
                    <use xlink:href="@/assets/i.svg#status-5"></use>
                </svg>
            </span>
        </span>

        <span v-if="order.status == 6" class="avatar w-full h-full flex items-center justify-center" :class="'bg-status-6'">
            <span class="w-5 h-5 flex items-center justify-center">
                <svg class="w-full h-full fill-white">
                    <use xlink:href="@/assets/i.svg#status-6"></use>
                </svg>
            </span>
        </span>

        <span v-if="order.status == 8" class="avatar w-full h-full flex items-center justify-center" :class="'bg-status-8'">
            <span class="w-5 h-5 flex items-center justify-center">
                <svg class="w-full h-full fill-white">
                    <use xlink:href="@/assets/i.svg#status-8"></use>
                </svg>
            </span>
        </span>

        <span v-if="order.status == 10" class="avatar w-full h-full flex items-center justify-center" :class="'bg-status-10'">
            <span class="w-5 h-5 flex items-center justify-center">
                <svg class="w-full h-full fill-white">
                    <use xlink:href="@/assets/i.svg#status-10"></use>
                </svg>
            </span>
        </span>

        <span v-if="order.status == 11" class="avatar w-full h-full flex items-center justify-center" :class="'bg-status-7'">
            <span class="w-5 h-5 flex items-center justify-center">
                <svg class="w-full h-full fill-white">
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
            </span>
        </span>

        <span
            v-if="order.status == 1 && [1, 7].includes(order.act)"
            class="avatar w-full h-full flex items-center justify-center"
            :class="'bg-status-12'"
        >
            <span class="w-5 h-5 flex items-center justify-center">
                <svg class="w-full h-full fill-white">
                    <use xlink:href="@/assets/i.svg#status-5"></use>
                </svg>
            </span>
        </span>

        <span v-if="order.status == 1 && order.act == 2" class="avatar w-full h-full flex items-center justify-center" :class="'bg-status-13'">
            <span class="w-5 h-5 flex items-center justify-center">
                <svg class="w-full h-full fill-white">
                    <use xlink:href="@/assets/i.svg#doc"></use>
                </svg>
            </span>
        </span>

        <span
            v-if="order.status == 1 && [3, 4, 5, 6, 8].includes(order.act)"
            class="avatar w-full h-full flex items-center justify-center"
            :class="'bg-status-1'"
        >
            <span class="w-5 h-5 flex items-center justify-center">
                <svg class="w-full h-full fill-white">
                    <use xlink:href="@/assets/i.svg#status-1"></use>
                </svg>
            </span>
        </span>
    </div>

    <div class="flex flex-col ml-4">
        <template v-if="order.status == 10">
            <span v-if="order.delay == 1">ОТЛОЖЕН: СРОЧНО</span>
            <span v-if="order.delay == 2">ОТЛОЖЕН: МЕНЕДЖЕР</span>
            <span v-if="order.delay == 3">ОТЛОЖЕН: КЛИЕНТ</span>
        </template>
        <template v-else>
            <span v-if="order.status == 1 && order.act < 1" class="text-gray-400 text-[10px] uppercase">новый</span>
            <span v-if="order.status == 3" class="text-gray-400 text-[10px] uppercase">назначен</span>
            <span v-if="order.status == 4" class="text-gray-400 text-[10px] uppercase">В работе</span>
            <span v-if="order.status == 5" class="text-gray-400 text-[10px] uppercase">На проверке</span>
            <span v-if="order.status == 6" class="text-gray-400 text-[10px] uppercase">завершен</span>
            <span v-if="order.status == 7" class="text-gray-400 text-[10px] uppercase">отклонен</span>
            <span v-if="order.status == 8" class="text-gray-400 text-[10px] uppercase">проверен КК</span>
            <span v-if="order.status == 10" class="text-gray-400 text-[10px] uppercase">отложен</span>
            <span v-if="order.status == 11" class="text-gray-400 text-[10px] uppercase">ОТКАЗ</span>
            <span v-if="order.status == 1 && [1, 7].includes(order.act)" class="text-gray-400 text-[10px] uppercase">ЗАПОЛНИТЬ АНКЕТУ</span>
            <span v-if="order.status == 1 && order.act == 2" class="text-gray-400 text-[10px] uppercase">ПРОВЕРИТЬ АНКЕТУ</span>
            <span v-if="order.status == 1 && order.act == 3" class="text-gray-400 text-[10px] uppercase">ЗАКАЗ В ТО</span>
        </template>
        <span class="font-latobold text-xl text-black">№{{ order.iid }}</span>
    </div>
</template>

<script>
export default {
    name: "OrderStatus",
    props: {
        order: Object,
    },
};
</script>
