<template>
  <div
    v-if="s.popup == 'task'"
    id="popnew"
    class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <TaskEdit @close="close" />
  </div>
  <div
    v-if="s.popup"
    @click="close"
    class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
    id="overlay"
  ></div>

  <div
    id="count"
    class="mb-9 mt-4 grid grid-cols-order__top place-content-center gap-x-14px gap-y-24px"
  >
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(1)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-edit"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TaskCounter"
        >
          {{ TaskCounter[1] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-20 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >Черновики</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(2)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#status-1"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TaskCounter"
        >
          {{ TaskCounter[2] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >Новые</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(4)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#status-3"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TaskCounter"
        >
          {{ TaskCounter[4] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-3 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >В работе</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(5)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#status-5"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TaskCounter"
        >
          {{ TaskCounter[5] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-5 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >Завершены</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(6)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TaskCounter"
        >
          {{ TaskCounter[6] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-6 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >Проверены</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(7)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-no"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TaskCounter"
        >
          {{ TaskCounter[7] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-7 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >Отклонены</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(19)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#status-11"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TaskCounter"
        >
          {{ TaskCounter[19] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-7 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >Нарушения</span
      >
    </div>
  </div>

  <div class="pb-4 w-full flex flex-wrap items-center">
    <h2
      class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4"
    >
      <span class="w-full text-lg leading-none text-teal-700">
        {{ this.TaskList?.length }}
      </span>
    </h2>
    <div class="flex-2_0 mr-15px min-w-[180px] my-4">
      <input
        type="text"
        class="h-9 w-full px-3 py-2 text-sm bg-slate-50"
        placeholder="Название"
        v-model="s.findme"
        @input="findTask(s)"
      />
    </div>

    <select
      class="my-2 h-9 w-44 mr-4 px-3 bg-slate-50"
      @change="findTask(s)"
      v-model="s.category"
    >
      <option value="" selected disabled="">Категории ТС</option>
      <option value="zero">0 ТС</option>
      <option value="1">1 ТС</option>
      <option value="10">2-10 ТС</option>
      <option value="30">11-30 ТС</option>
      <option value="50">31-50 ТС</option>
      <option value="99">>50 ТС</option>
    </select>

    <select
      class="my-2 h-9 w-44 mr-4 px-3 bg-slate-50"
      @change="findTask(s)"
      v-model="s.dep"
    >
      <option value="" selected disabled="">Отдел</option>
      <option value="Менеджеры">Менеджеры</option>
      <option value="Логисты">Логисты</option>
      <option value="КК">КК</option>
      <option value="Бухгалтерия">Бухгалтерия</option>
      <option value="Техподдержка">Техподдержка</option>
    </select>

    <select
      class="my-2 h-9 w-44 mr-4 px-3 bg-slate-50"
      @change="findTask(s)"
      v-model="s.type"
    >
      <option value="" selected disabled="">Тип</option>
      <option v-if="s.dep == 'Менеджеры' || s.dep == ''" value="101">
        Как дела?
      </option>
      <option v-if="s.dep == 'Менеджеры' || s.dep == ''" value="102">
        Пролонгация заказа
      </option>
      <option v-if="s.dep == 'Менеджеры' || s.dep == ''" value="103">
        Проблема с док. по активизации
      </option>
      <option v-if="s.dep == 'Менеджеры' || s.dep == ''" value="104">
        Создание МЧД
      </option>
      <option v-if="s.dep == 'Менеджеры' || s.dep == ''" value="105">
        Перезвонить
      </option>

      <option v-if="s.dep == 'Логисты' || s.dep == ''" value="201">
        Запись клиента
      </option>
      <option v-if="s.dep == 'Логисты' || s.dep == ''" value="202">
        Отключение ТС неуплата
      </option>
      <option v-if="s.dep == 'Логисты' || s.dep == ''" value="203">
        Отключение ТС хочет клиент
      </option>

      <option v-if="s.dep == 'КК' || s.dep == ''" value="501">
        Возврат клиента
      </option>

      <option v-if="s.dep == 'Бухгалтерия' || s.dep == ''" value="801">
        Запрос акта сверки
      </option>
      <option v-if="s.dep == 'Бухгалтерия' || s.dep == ''" value="802">
        Возврат дс
      </option>
      <option v-if="s.dep == 'Бухгалтерия' || s.dep == ''" value="803">
        Взаимозачёт
      </option>
      <option v-if="s.dep == 'Бухгалтерия' || s.dep == ''" value="804">
        Прикрепить ДС
      </option>
      <option v-if="s.dep == 'Бухгалтерия' || s.dep == ''" value="805">
        Отвязать ДС
      </option>
      <option v-if="s.dep == 'Бухгалтерия' || s.dep == ''" value="806">
        Спец.цена
      </option>
      <option v-if="s.dep == 'Бухгалтерия' || s.dep == ''" value="807">
        Мониторинг выставить счет
      </option>
      <option v-if="s.dep == 'Бухгалтерия' || s.dep == ''" value="808">
        Отказ по заказу
      </option>

      <option v-if="s.dep == 'Техподдержка' || s.dep == ''" value="901">
        Ошибка
      </option>
      <option v-if="s.dep == 'Техподдержка' || s.dep == ''" value="902">
        Улучшение
      </option>
    </select>

    <select
      class="my-2 h-9 w-44 mr-4 px-3 bg-slate-50"
      @change="findTask(s)"
      v-model="s.creator"
    >
      <option value="" selected disabled="">Автор</option>
      <template v-for="m in selectWorkers" :key="m">
        <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
      </template>
    </select>

    <select
      class="my-2 h-9 w-44 mr-4 px-3 bg-slate-50"
      @change="findTask(s)"
      v-model="s.worker"
    >
      <option value="" selected disabled="">Ответственный</option>
      <template v-for="m in selectWorkers" :key="m">
        <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
      </template>
    </select>

    <div class="flex py-4">
      <a
        v-if="s.clear"
        class="v-btn bg-gray-500 hover:bg-gray-400 w-max mr-4"
        @click="Clear"
      >
        <span class="v-btn_text">Сброс</span>
      </a>
      <a
        class="v-btn bg-green-500 hover:bg-green-400 flex items-center w-max"
        @click="addTask"
      >
        <svg
          class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
        >
          <use xlink:href="@/assets/i.svg#ico-no"></use>
        </svg>
        <span class="v-btn_text">Создать</span>
      </a>
    </div>
  </div>

  <div class="flex items-center">
    <select class="mb-4" v-model="itemsInPage" style="padding: 7px">
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
    <Paginator :count="pageCount" v-model="page" />
    <div class="bg-white mx-2 py-1 px-2 rounded-md mb-4">
      <input
        v-if="['Studio', 'Director'].includes(role)"
        class="mr-2"
        type="checkbox"
        id="checkall"
        @change="SelectAllPage()"
        v-model="massCheckValue"
      />
      <label for="checkall">Выделить все</label>
    </div>

    <div class="flex items-center mx-4 mb-4" v-if="checked?.length">
      С отмеченными ({{ checked.length }})
      <select v-model="action" class="my-2 mx-2 h-9 w-44 mr-4 bg-slate-50">
        <option value="">Ничего</option>
        <option value="del">Удалить</option>
      </select>
      <button
        :disabled="!action"
        @click="MassActionClick"
        :class="{ 'bg-rose-500': action, 'bg-rose-300': !action }"
        class="mr-15px v-btn duration-100 transition ease-linear"
      >
        Выполнить
      </button>
      <button
        class="v-btn duration-100 transition ease-linear bg-orange-400"
        @click="
          action = '';
          checked = [];
        "
      >
        Сбросить
      </button>
    </div>
  </div>

  <div
    id="list"
    class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto"
    v-if="TaskList?.length > 0 && !loadingChange"
  >
    <template v-for="item in PaginatedTaskList" :key="item">
      <div class="wrap-task-flex">
        <div
          class="grid grid-cols-task-table p-3 items-center v-bbgray cursor-pointer transition duration-300 hover:shadow-v1 odd:bg-white bg-teal-light max-m:grid-cols-all-mobile max-m:auto-rows-[50px]"
          :class="{
            'status-20': item.status == 1,
            'status-1': item.status == 2,
            'status-3': item.status == 3,
            'status-5': item.status == 5,
            'status-6': item.status == 6,
          }"
        >
          <div
            class="col-span-2"
            style="
              display: flex;
              align-items: center;
              justify-content: flex-start;
            "
          >
            <div
              v-if="['Studio', 'Director'].includes(role)"
              class="cell w5 mr-2"
            >
              <input
                v-if="CanBeChecked(item)"
                type="checkbox"
                :value="item.id"
                v-model="checked"
              />
            </div>
            <svg
              class="min-w-5 min-h-5 w-5 h-5 mr-2 fill-status-20"
              v-if="item.status == 1"
            >
              <use xlink:href="@/assets/i.svg#ico-edit"></use>
            </svg>
            <svg
              class="min-w-5 min-h-5 w-5 h-5 mr-2 fill-status-1"
              v-if="item.status == 2"
            >
              <use xlink:href="@/assets/i.svg#status-1"></use>
            </svg>
            <svg
              class="min-w-5 min-h-5 w-5 h-5 mr-2 fill-status-3"
              v-if="item.status == 4"
            >
              <use xlink:href="@/assets/i.svg#status-3"></use>
            </svg>
            <svg
              class="min-w-5 min-h-5 w-5 h-5 mr-2 fill-status-5"
              v-if="item.status == 5"
            >
              <use xlink:href="@/assets/i.svg#status-5"></use>
            </svg>
            <svg
              class="min-w-5 min-h-5 w-5 h-5 mr-2 fill-status-6"
              v-if="item.status == 6"
            >
              <use xlink:href="@/assets/i.svg#status-8"></use>
            </svg>
            <svg
              class="min-w-5 min-h-5 w-5 h-5 mr-2 fill-status-11"
              v-if="item.status == 7"
            >
              <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
            <svg
              class="min-w-5 min-h-5 w-5 h-5 mr-2 fill-status-11"
              v-if="item.status == 19"
            >
              <use xlink:href="@/assets/i.svg#status-11"></use>
            </svg>

            <div
              @click="showTask(item)"
              title="Было нарушение по времени"
              class="min-w-5 min-h-5 w-5 h-5 mr-2"
            >
              <svg v-if="item.violate" class="w-5 h-5 fill-status-11">
                <use xlink:href="@/assets/i.svg#status-11"></use>
              </svg>
            </div>

            <div
              @click="showTask(item)"
              class="min-w-5 min-h-5 w-5 h-5"
              v-if="!item.orderDates && item.type == 201"
              title="Не назначено время выполнения"
            >
              <svg class="w-5 h-5 fill-status-7">
                <use xlink:href="@/assets/i.svg#ico-edit"></use>
              </svg>
            </div>
            <div
              @click="showTask(item)"
              class="min-w-5 min-h-5 w-5 h-5"
              v-if="item.orderDates && item.type == 201"
              title="Время выполнения назначено"
            >
              <svg class="w-5 h-5 fill-status-5">
                <use xlink:href="@/assets/i.svg#ico-edit"></use>
              </svg>
            </div>
          </div>

          <div @click="showTask(item)" class="px-4">#{{ item.iid }}</div>

          <div @click="showTask(item)">
            {{ moment(item.date).format("DD.MM.YYYY hh:mm") }}
          </div>

          <div
            @click="showTask(item)"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <template v-for="t in tasksTypes" :key="t">
              <template v-if="t.id == item.type">
                <div class="flex flex-col items-start">
                  <span
                    class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                    >{{ t.dep }}</span
                  >
                  <span class="text-base font-latobold">{{ t.name }}</span>
                </div>
              </template>
            </template>
          </div>

          <div @click="showTask(item)" class="flex items-center">
            <div v-if="item.cname" class="flex">
              <span
                class="min-w-[2.75rem] w-11 h-11 rounded-full mr-4 overflow-hidden flex items-center justify-center text-white text-lg"
                :class="'bg-status-' + item.status"
              >
                {{ item.cname.slice(0, 1) }}
              </span>
              <div class="flex flex-col items-start">
                <span
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                  >КЛИЕНТ</span
                >
                <span>{{ item.cname }}</span>
              </div>
            </div>
          </div>

          <div @click="showTask(item)" class="flex items-center">
            <div
              class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden"
              :class="{
                'bg-status-20': item.status == 1,
                'bg-status-1': item.status == 2,
                'bg-status-6': item.status == 6,
              }"
            >
              <IcoPic :pic="item.wpicture" :name="item.wname" />
            </div>
            <div class="flex flex-col">
              <span class="text-[10px] text-gray-400 leading-none">АВТОР</span>
              <span
                class="text-base font-semibold break-all max-h-10 leading-5 whitespace-pre-wrap"
                >{{ item.wname }}</span
              >
            </div>
          </div>

          <div class="flex items-center">
            <div
              class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden"
              :class="{
                'bg-status-20': item.status == 1,
                'bg-status-1': item.status == 2,
                'bg-status-6': item.status == 6,
              }"
            >
              <IcoPic :pic="item.upicture" :name="item.uname" />
            </div>
            <div class="flex flex-col">
              <span class="text-[10px] text-gray-400 leading-none"
                >ОТВЕТСТВЕННЫЙ</span
              >
              <span
                class="text-base font-semibold break-all max-h-10 leading-5 whitespace-pre-wrap"
                >{{ item.uname }}</span
              >
            </div>
          </div>
        </div>
        <div
          @click="showTask(item)"
          class="flex items-center"
          style="margin-right: 15px; margin-left: 15px"
          v-if="['Studio'].includes(role) && item.status == 1"
        >
          <button
            class="v-btn bg-rose-500 hover:bg-rose-400 flex items-center w-max"
            style="color: #fff"
            @click="delTask(item)"
          >
            <svg class="fill-white w-16px h-16px mr-3">
              <use xlink:href="@/assets/i.svg#ico-delete"></use>
            </svg>
            Удалить
          </button>
        </div>
        <div
          @click="showTask(item)"
          class="flex items-center"
          style="margin-left: 25px"
          v-if="
            (role == 'Studio' || parseInt(item.who) == parseInt(profile.uid)) &&
            (item.status == 19 || item.status == 5)
          "
        >
          <button
            class="v-btn bg-gray-500 flex items-center w-max"
            style="color: #fff"
            @click="terminateViolationTask(item)"
          >
            Принять
          </button>
        </div>
      </div>
    </template>
  </div>
  <div
    v-else-if="s.loader || loadingChange"
    class="absolute top-60 left-0 flex-cc w-full"
  >
    <img src="https://api.tahocrm.ru/images/loader.svg" />
  </div>
  <div
    v-else
    class="absolute top-60 le-ft-0 flex-cc w-full text-slate-300 text-2xl text-center"
  >
    Нет данных
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TaskEdit from "../components/TaskEdit";
import Paginator from "@/components/ui/Paginator";
import moment from "moment";

export default {
  name: "TaskList",

  components: { TaskEdit, Paginator },

  data() {
    return {
      moment: moment,
      page: 0,
      itemsInPage: 10,
      loadingChange: true,

      checked: [],
      massCheckValue: false,
      action: "",
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "TaskList",
      "TaskCounter",
      "selectWorkers",
      "tasksTypes",
      "tasksWorkers",
    ]),
    pageCount() {
      return Math.ceil((this.TaskList?.length ?? 0) / this.itemsInPage);
    },
    PaginatedTaskList() {
      return this.TaskList.slice(
        this.page * this.itemsInPage,
        (this.page + 1) * this.itemsInPage
      );
    },
  },

  methods: {
    ...mapActions([
      "RemoveSelectedTasks",
      "getTasks",
      "addTask",
      "delTask",
      "showTask",
      "setTask",
      "findTask",
      "statusTask",
      "find",
      "setSettings",
      "clearSettings",
      "closePopup",
      "setTaskStatus",
    ]),

    CanBeChecked(item) {
      if (this.$store.state.profile.role == "Studio") return true;
      if (this.$store.state.profile.role == "Manager")
        return item?.manager_id == this.$store.state.profile.profile.id;
      return false;
    },

    SelectAllPage() {
      console.log(this.massCheckValue);
      if (this.massCheckValue) {
        this.checked.push(...this.PaginatedTaskList.map(({ id }) => id));
      } else {
        let ids = new Set(this.PaginatedTaskList.map(({ id }) => id));
        this.checked = this.checked.filter((id) => !ids.has(id));
      }
    },

    async MassActionClick() {
      switch (this.action) {
        case "del":
          await this.RemoveSelectedTasks({ checked: this.checked });

          this.checked = [];
          this.action = "";
          this.massCheckValue = false;
          return true;
      }
    },

    filterStatus(status) {
      this.s.status = status;
      this.statusTask(this.s);
    },

    Clear() {
      this.clearSettings();
      this.findTask(this.s);
    },

    close() {
      this.setTask(null);
      this.findTask(this.s);

      this.closePopup();
    },
    show(e) {
      console.log(e);
    },
    async terminateViolationTask(task) {
      this.loadingChange = true;
      await this.setTask(task);
      await this.setTaskStatus(6);

      setTimeout(() => {
        this.findTask(this.s);
        this.statusTask(this.s);
        this.loadingChange = false;
      }, 1000);
    },
  },

  mounted() {
    this.getTasks();
    this.loadingChange = false;
    window.DEBUG_TASKS = this;
  },
};
</script>

<style scoped>
.grid-cols-task-table {
  width: calc(100% - 135px);
}
.wrap-task-flex {
  display: flex;
  align-items: center;
}
.grid-cols-task-table {
  grid-template-columns: 5rem 2.5rem 5rem 10rem 10rem repeat(
      3,
      minmax(10rem, 1fr)
    );
}
@media (max-width: 1367px) {
  .grid-cols-task-table {
    grid-template-columns: 5rem 5rem 10rem 10rem 4rem 10rem 10rem;
  }
}
</style>
