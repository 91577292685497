<template>
  <div
    v-if="s.popup == 'areYouSure'"
    id="popnew"
    class="max-w-[500px] max-h-[260px] p-5 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-1/4 bottom-4 overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <h1 class="font-latobold text-xl text-center mb-3 no-select">
      Подтвердите действие
    </h1>
    <p class="text-base text-center no-select">
      Вы собираетесь удалить <b>касания в количестве {{ checked.length }} шт.</b
      ><br />Вместе с ними будут удалены все связанные с этими касаниями задачи,
      ЛИДы и их задачи, заявки и их задачи, счета и их задачи, а так же заказы и
      их задачи
    </p>
    <p class="text-base text-center my-3 no-select">
      <b>Данное действие является необратимым</b>
    </p>
    <!-- <p class="text-base text-center no-select">
      Для удаления введите код "<span class="text-xl text-rose-500"
        ><strong>удалить касание</strong></span
      >" в текстовое поле ниже, чтобы продолжить
    </p> -->

    <div class="flex justify-center items-center mt-4">
      <!-- <input
        v-model="specialWord"
        type="text"
        class="h-9 px-3 py-2 text-sm bg-slate-50"
        placeholder="Кодовое слово"
      /> -->
      <button
        class="bg-rose-500 px-8 v-btn duration-100 transition ease-linear text-white"
        @click="MassActionClick()"
      >
        Удалить
      </button>
    </div>
  </div>
  <div
    v-if="s.popup"
    @click="close"
    id="overlay"
    class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
  ></div>

  <div
    class="mb-9 mt-4 grid grid-cols-order__top place-content-center gap-x-14px gap-y-24px"
  >
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(1)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#status-1"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TouchesCounter"
        >
          {{ TouchesCounter[0] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >Открытые</span
      >
    </div>

    <!-- Rhomb #3 -->
    <!-- <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(3)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-3"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="TouchesCounter">
                    {{ TouchesCounter[3] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-3 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >НАЗНАЧЕНЫ</span
            >
        </div> -->

    <!-- <div
            v-if="['Studio', 'Check'].includes(role)"
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(4)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-4"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="TouchesCounter">
                    {{ TouchesCounter[4] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-10 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >В РАБОТЕ</span
            >
        </div> -->

    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(2)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TouchesCounter"
        >
          {{ TouchesCounter[1] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-6 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >Закрытые</span
      >
    </div>

    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(3)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-no"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="TouchesCounter"
        >
          {{ TouchesCounter[2] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-7 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >Отмененные</span
      >
    </div>
    <!-- 
        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterDel()"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-11"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="TouchesCounter">
                    {{ TouchesCounter[3] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-grey group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Удаленные</span
            >
        </div> -->

    <!-- 
		<div v-if="role != 'Client'" class="round f_red btn show" @click="filterStatus(19)">
			<div class="item">
				<svg class="ico"><use xlink:href="@/assets/i.svg#status-11"></use></svg>
				<h2 v-if="TouchesCounter">{{ TouchesCounter[19] }}</h2>
				<svg class="romb"><use xlink:href="@/assets/i.svg#bg-romb"></use></svg>
			</div>
			<span class="name">Нарушения</span>
		</div>
	-->
  </div>

  <div class="pb-4 w-full flex flex-wrap items-center">
    <h2
      class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4"
    >
      <span class="w-full text-lg leading-none text-teal-700">
        {{ TouchesList?.length }}
      </span>
    </h2>

    <div class="flex-2_0 mr-15px w-[200px] my-4">
      <input
        type="text"
        class="h-9 w-full px-3 py-2 text-sm bg-slate-50"
        placeholder="Название"
        v-model="s.findme"
        @input="findTouch(s)"
      />
    </div>

    <input
      class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[9rem] w-[9rem] mr-4"
      type="date"
      v-model="s.start"
      @change="findTouch(s)"
    />

    <input
      class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[9rem] w-[9rem] mr-4"
      type="date"
      v-model="s.end"
      @change="findTouch(s)"
    />

    <template v-if="['Manager', 'Logist', 'Studio', 'Check'].includes(role)">
      <select
        class="my-2 h-9 w-40 mr-4 px-3 bg-slate-50"
        @change="findTouch(s)"
        v-model="s.manager"
      >
        <option disabled="true" selected="" value="">Менеджер</option>
        <template v-for="m in selectManagers" :key="m">
          <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
        </template>
      </select>
    </template>

    <template v-if="['Manager', 'Logist', 'Studio', 'Check'].includes(role)">
      <select
        class="my-2 h-9 min-w-[11rem] w-[11rem] mr-4 px-3 bg-slate-50"
        @change="findTouch(s)"
        v-model="s.creator"
      >
        <option disabled="true" value="">Создатель</option>
        <option value="1">Робот</option>
        <template v-for="m in selectManagers" :key="m">
          <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
        </template>
      </select>
      <select
        @change="findTouch(s)"
        v-model="s.business_proc"
        class="my-2 h-9 min-w-[11rem] w-[11rem] mr-4 px-3 bg-slate-50"
      >
        <option value="" disabled selected>Выберите опцию</option>
        <option value="1">Пролонгация тахографы</option>
        <option value="2">Пролонгация настройки ЕСТР</option>
        <option value="3">Пролонгация пропуска</option>
        <option value="4">Пролонгация карты</option>
        <option value="5">Контроль качества</option>
        <option value="6">Реанимация неактивных клиентов</option>
        <option value="7">Реанимация клиентов без заказов</option>
        <option value="8">Рекламации</option>
        <option value="9">Как дела</option>
        <option value="10">Общий</option>
        <option value="11">АБ Мониторинг</option>
        <option value="12">Агент</option>
      </select>
    </template>

    <div class="flex py-4">
      <button class="v-btn bg-gray-500 hover:bg-gray-400 mr-4" v-if="s.clear">
        <span class="v-btn_text" @click="Clear">Сброс</span>
      </button>
      <!-- <template v-if="['Manager', 'Logist', 'Studio', 'Check'].includes(role)">
                <button class="v-btn bg-green-500 hover:bg-green-400">
                    <span class="v-btn_text flex items-center" @click="addLead(null)">
                        <svg class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear">
                            <use xlink:href="@/assets/i.svg#ico-no"></use>
                        </svg>
                        Создать
                    </span>
                </button>
            </template> -->
    </div>
  </div>

  <div class="flex items-center">
    <select
      class="mb-4"
      v-model="touchPaginator.itemsInPage"
      style="padding: 7px"
    >
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
    <Paginator :count="pageCount" v-model="touchPaginator.page" />
    <div class="flex items-center mx-4 mb-4" v-if="checked?.length">
      С отмеченными ({{ checked.length }})
      <select v-model="action" class="my-2 mx-2 h-9 w-44 mr-4 bg-slate-50">
        <option value="">Ничего</option>
        <option value="del">Удалить</option>
      </select>
      <button
        :disabled="!action"
        @click="s.popup = 'areYouSure'"
        :class="{ 'bg-rose-500': action, 'bg-rose-300': !action }"
        class="mr-15px v-btn duration-100 transition ease-linear text-white"
      >
        Выполнить
      </button>
      <button
        class="v-btn duration-100 transition ease-linear bg-orange-400"
        @click="
          action = '';
          checked = [];
        "
      >
        Сбросить
      </button>
    </div>
  </div>

  <div
    v-if="TouchesList?.length > 0"
    class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto"
  >
    <div class="grid grid-cols-14 h-10 v-bbgray px-4 uppercase items-center">
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
      >
        <div
          class="flex font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          <input
            v-if="['Studio', 'Director'].includes(role)"
            class="mr-2"
            type="checkbox"
            @change="SelectAllPage()"
            v-model="massCheckValue"
          />
          <p>№</p>
        </div>
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
      >
        СОЗДАНА
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-4"
      >
        Клиент
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        НАПРАВЛЕНИЕ
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        ДЕТАЛИ
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        МЕНЕДЖЕР
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        СОЗДАТЕЛЬ
      </div>
    </div>
    <template v-for="item in PaginatedTouchesList" :key="item">
      <a
        class="grid grid-cols-14 px-4 py-2 gap-2 v-bbgray bg-teal-light odd:bg-white items-center transition duration-300 cursor-pointer hover:shadow-v1 max-2xl:grid-cols-order_m max-2xl:auto-rows-[50px]"
        :class="'status-' + item.status"
      >
        <div class="flex w-24">
          <div
            v-if="['Studio', 'Director'].includes(role)"
            class="cell w5 mr-2"
          >
            <input
              v-if="CanBeChecked(item)"
              type="checkbox"
              :value="item.id"
              v-model="checked"
            />
          </div>
          <template v-if="item.violate || item.status == 8">
            <svg @click="showTouch(item)" class="w-5 h-5 mr-2">
              <use xlink:href="@/assets/i.svg#status-11"></use>
            </svg>
          </template>
          <template v-else>
            <div @click="showTouch(item)" class="w-5 h-5 mr-2">
              <svg
                v-if="item.status == 1"
                class="w-full h-full"
                :class="'fill-status-' + item.status"
              >
                <use xlink:href="@/assets/i.svg#status-1"></use>
              </svg>
              <svg
                v-if="item.status == 2"
                class="w-full h-full"
                :class="'fill-status-6'"
              >
                <use xlink:href="@/assets/i.svg#status-6"></use>
              </svg>
              <svg
                v-if="item.status == 3"
                class="w-full h-full"
                :class="'fill-status-11'"
              >
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
            </div>
          </template>
          #{{ item.id }}
        </div>

        <div @click="showTouch(item)" class="items-center">
          <div class="flex items-center ml-2">
            <svg class="fill-gray-400 w-2 h-2 mr-1 rotate-45">
              <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
            <div class="text-gray-400 text-xs">
              {{ moment(item.created_at).format("DD.MM.YYYY") }}
            </div>
          </div>
        </div>

        <div
          @click="showTouch(item)"
          class="text-black flex w-full h-full items-center col-span-4"
        >
          <span
            class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px]"
            :class="{
              'bg-status-1': item.status == 1,
              'bg-status-6': item.status == 2,
              'bg-status-9': item.status == 3,
              'bg-status-grey': item.del == 1,
            }"
          >
            {{ item.cname?.slice(0, 1) }}
          </span>
          <div
            @click="showTouch(item)"
            class="flex flex-col justify-center overflow-hidden pr-4"
          >
            <span
              class="text-base font-semibold break-all max-h-10 leading-5"
              >{{ item.cname }}</span
            >
          </div>
        </div>

        <div
          @click="showTouch(item)"
          class="flex flex-col text-black text-xs tex-left max-h-16 col-span-2"
        >
          <span>{{ direction[item.direction] }}</span>
          <span>{{ type[item.type] }}</span>
        </div>

        <div
          @click="showTouch(item)"
          class="flex flex-col text-black text-xs tex-left max-h-16 col-span-2"
        >
          <span>{{ channel[item.channel] }}</span>
          <span>{{ detail[item.detail] }}</span>
        </div>

        <div
          @click="showTouch(item)"
          v-if="item.mname"
          class="font-latoblod-serif font-[16px] text-v-gray relative flex justify-start items-center col-span-2 max-m:col-span-2"
        >
          <div
            class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden"
            :class="'bg-status-' + item.status"
          >
            <IcoPic :pic="item.mpicture" :name="item.mname" />
          </div>
          <div class="flex flex-col justify-center overflow-hidden">
            <span class="text-[10px] text-gray-400 leading-none uppercase"
              >Менеджер</span
            >
            <span class="text-black overflow-hidden">{{ item.mname }}</span>
          </div>
        </div>
        <div
          @click="showTouch(item)"
          v-else
          class="flex justify-start items-center col-span-2 max-m:col-span-2"
        >
          <span
            class="w-11 h-11 bg-slate-200 flex justify-center rounded-full items-center overflow-hidden mr-2"
          >
            <svg class="w-4 h-4 fill-white">
              <use xlink:href="@/assets/i.svg#ico-avatar"></use>
            </svg>
          </span>
        </div>
        <div
          @click="showTouch(item)"
          v-if="item.wname"
          class="font-latoblod-serif font-[16px] text-v-gray relative flex justify-start items-center col-span-2 max-m:col-span-2"
        >
          <div
            class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden"
            :class="'bg-status-' + item.status"
          >
            <IcoPic :pic="item.wpicture" :name="item.wname" />
          </div>
          <div class="flex flex-col justify-center overflow-hidden">
            <span class="text-[10px] text-gray-400 leading-none uppercase"
              >Создатель</span
            >
            <span class="text-black overflow-hidden">{{ item.wname }}</span>
          </div>
        </div>
        <div
          @click="showTouch(item)"
          v-else
          class="flex justify-start items-center col-span-2 max-m:col-span-2"
        >
          <span
            class="w-11 h-11 bg-slate-200 flex justify-center rounded-full items-center overflow-hidden mr-2"
          >
            <svg class="w-4 h-4 fill-white">
              <use xlink:href="@/assets/i.svg#ico-avatar"></use>
            </svg>
          </span>
        </div>
      </a>
    </template>
  </div>
  <div v-else-if="s.loader" class="absolute top-60 left-0 flex-cc w-full">
    <img src="https://api.tahocrm.ru/images/loader.svg" />
  </div>
  <div
    v-else
    class="absolute top-60 left-0 flex-cc w-full text-slate-300 text-2xl text-center"
  >
    Нет данных
  </div>
</template>

<script>
// import API from "@/services/api";

import { mapActions, mapGetters } from "vuex";
import moment from "moment";
// import LineIco from "../components/ui/LineIco.vue";
import Paginator from "@/components/ui/Paginator";

export default {
  name: "TouchesListPage",

  components: {
    // LineIco,
    Paginator,
  },

  data() {
    return {
      moment: moment,

      checked: [],
      action: "",
      massCheckValue: false,
      specialWord: "",

      direction: { 1: "ВХОДЯЩИЙ", 2: "ИСХОДЯЩИЙ" },
      type: { 1: "ПЕРВИЧНЫЙ", 2: "ВТОРИЧНЫЙ" },
      detail: {
        101: 'Задача "Как дела"',
        102: 'Задача "Пролонгация"',
        103: "Контроль качества",
        104: "Агент-сотрудник",
      },
      channel: {
        1: "Интернет",
        2: "Рекомендация",
        3: "Агент",
        4: "Обзвон новой базы",
        15: "Обзвон новой базы",
        21: "Вторичная клиентская база",
        22: "Рассылка",
        23: "Агент-сотрудник",
        31: "Вторичная клиентская база",
      },
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "TouchesList",
      "TouchesCounter",
      "countLeads",
      "countTouches",
      "selectManagers",
      "selectMasters",
      "touchPaginator",
    ]),
    page() {
      return this.touchPaginator.page;
    },
    itemsInPage() {
      return this.touchPaginator.itemsInPage;
    },
    pageCount() {
      return Math.ceil((this.TouchesList?.length ?? 0) / this.itemsInPage);
    },
    PaginatedTouchesList() {
      return this.TouchesList.slice(
        this.page * this.itemsInPage,
        (this.page + 1) * this.itemsInPage
      );
    },
  },

  methods: {
    ...mapActions([
      "RemoveSelectedTouches",
      "getTouches",
      "addTouch",
      "setLead",
      "showTouch",
      "statusTouch",
      "findTouch",
      "setSettings",
      "clearSettings",
      "fillTouches",
    ]),

    CanBeChecked(item) {
      if (this.$store.state.profile.role == "Studio") return true;
      if (this.$store.state.profile.role == "Manager")
        return item?.manager_id == this.$store.state.profile.profile.id;
      return false;
    },
    SelectAllPage() {
      console.log(this.massCheckValue);
      if (this.massCheckValue) {
        this.checked.push(...this.PaginatedTouchesList.map(({ id }) => id));
      } else {
        let ids = new Set(this.PaginatedTouchesList.map(({ id }) => id));
        this.checked = this.checked.filter((id) => !ids.has(id));
      }
    },

    async MassActionClick() {
      switch (this.action) {
        case "del":
          // if (this.specialWord.toLowerCase() == "удалить касание") {
          await this.RemoveSelectedTouches({ checked: this.checked });

          this.checked = [];
          this.action = "";
          this.specialWord = "";
          this.s.popup = null;

          this.massCheckValue = false;
          return true;
        // } else {
        //   alert("Код введен неверно, удаление не будет произведено!");
        //   this.specialWord = "";
        // }
      }
    },

    close() {
      this.s.popup = null;
      this.specialWord = "";
    },

    filterStatus(status) {
      this.s.status = status;
      this.s.claim = "";
      this.s.doc = "";
      this.s.del = "";
      this.setSettings(this.s);
      this.statusTouch(this.s);
    },

    filterDel() {
      this.s.status = "";
      this.s.claim = "";
      this.s.doc = "";
      this.s.del = "1";
      this.findTouch(this.s);
    },

    filterPayStatus() {
      this.s.pay_status = 1;
      this.findTouch(this.s);
    },

    filterClaim() {
      this.s.status = "";
      this.s.claim = 1;
      this.setSettings(this.s);
      this.statusLead(this.s);
    },

    filterDoc() {
      this.s.status = "";
      this.s.doc = 1;
      this.setSettings(this.s);
      this.statusLead(this.s);
    },

    filterTrans() {
      this.s.status = "";
      this.s.trans = 1;
      this.setSettings(this.s);
      this.statusLead(this.s);
    },

    filterPay(pay) {
      this.s.pay = pay;
      this.setSettings(this.s);
      this.findTouch(this.s);
    },

    filterCategory(category) {
      this.s.category = category;
      this.setSettings(this.s);
      this.findTouch(this.s);
    },

    Clear() {
      // console.log('clear!');
      this.clearSettings();
      this.fillTouches();
      this.getTouches();
    },
  },

  async mounted() {
    this.getTouches();
    // this.Clear();

    // window.DEBUG_REPORT = this;

    //     this.TouchesList = (await API.GET('touch/touches')).data.touches;
    //     console.log("Прилетело", this.TouchesList);
  },
};
</script>

<style>
.bg-status-8 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 72 126 / var(--tw-bg-opacity));
}
.fill-status-grey {
  fill: #8b8b8b;
}
.bg-status-grey {
  background: #8b8b8b !important;
}
</style>

<style scoped>
.no-select {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Стандартный синтаксис */
}
</style>
