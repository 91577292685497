<template>
  <div>
    <SalaryBonus @close="close" v-if="salaryBonuses" />
    <SalaryOrder @close="close" v-if="salaryOrders" />
    <div
      v-if="loader && !salaryBonuses && !salaryOrders"
      class="absolute top-32 left-0 flex-cc w-full"
    >
      <img src="https://api.tahocrm.ru/images/loader.svg" />
    </div>
    <div
      v-if="
        !loader &&
        salaryBonuses &&
        !salaryBonuses?.length &&
        salaryOrders &&
        salaryOrders?.length
      "
      class="absolute top-32 left-0 flex-cc w-full text-slate-300 text-2xl text-center"
    >
      Нет данных
    </div>
    <div
      v-if="SalaryList && !loader && !salaryBonuses && !salaryOrders"
      class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto mt-4 h-max"
    >
      <select
        class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4"
        v-model="SelectedMonth"
        @change="getSalaries({ month: SelectedMonth, master: s.master })"
      >
        <option value="">Текущий</option>
        <option
          v-for="month in AvailableMonths"
          :value="month"
          :key="month"
          v-html="monthDisplay(month)"
        />
      </select>
      <select
        class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4"
        @change="getSalaries({ month: SelectedMonth, master: s.master })"
        v-model="s.master"
      >
        <option value="">Инженер</option>
        <template v-for="m in selectMasters" :key="m">
          <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
        </template>
      </select>
      <div
        class="grid grid-cols-9 max-m:hidden v-bbgray p-4 uppercase items-center"
      >
        <div class="font-latobold text-gray-400 text-[11px]">Мастер</div>
        <div class="font-latobold text-gray-400 text-[11px]">Заказы</div>
        <div class="font-latobold text-gray-400 text-[11px]">Назначено</div>
        <div class="font-latobold text-gray-400 text-[11px]">Сделано</div>
        <div class="font-latobold text-gray-400 text-[11px]">Одобрено</div>
        <div class="font-latobold text-gray-400 text-[11px]">Выходные</div>
        <div class="font-latobold text-gray-400 text-[11px]">Бонусы</div>
        <div class="font-latobold text-gray-400 text-[11px]">Штрафы</div>
        <div class="font-latobold text-gray-400 text-[11px]">Итогo</div>
      </div>
      <div v-for="(monthContent, month) in SalaryList" :key="month">
        <div
          v-if="['Logist', 'Studio'].includes(role)"
          :key="month"
          class="flex v-bbgray justify-center items-center uppercase w-full min-h-60 text-sm font-latobold"
        >
          {{ selectMonth[parseInt(month.slice(5, 7))] }}
          <sup>'{{ month.slice(2, 4) }}</sup>
        </div>
        <template v-for="(item, id) in monthContent">
          <div
            :key="id"
            style="grid-template-columns: 1fr 4fr 1fr 3fr"
            class="grid max-2xl:grid-cols-4 gap-2 min-h-60 px-4 py-2 v-bbgray bg-teal-light odd:bg-white items-center transition duration-300 cursor-pointer"
            v-if="
              ['Logist', 'Studio'].includes(role) ||
              id === $store.state.profile.profile.uid
            "
          >
            <template v-if="['Logist', 'Studio'].includes(role)">
              <template v-for="master in selectWorkers" :key="master">
                <template v-if="master.uid == id">
                  <div
                    class="font-latoblod-serif font-[16px] flex justify-start items-center max-m:col-span-3"
                  >
                    <div
                      class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden"
                      :class="'bg-status-' + master.status"
                    >
                      <IcoPic :pic="master.picture" :name="master.name" />
                    </div>
                    <div class="flex flex-col justify-center overflow-hidden">
                      <span
                        class="text-[10px] text-gray-400 leading-none uppercase"
                        >Инженер</span
                      >
                      <span class="text-black"
                        >{{ master.secondname }} {{ master.name[0] }}.
                      </span>
                    </div>
                  </div>
                </template>
              </template>
            </template>

            <template v-if="['Master'].includes(role)">
              <div>
                {{ selectMonth[parseInt(month.date.slice(5, 7))] }}
                <sup>'{{ month.date.slice(2, 4) }}</sup>
              </div>
            </template>

            <div
              @click="pickMonth({ date: month, uid: id })"
              class="hover:shadow-v2"
              style="
                border-radius: 5px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
              "
            >
              <div>
                <div
                  v-if="item.count"
                  class="text-gray-400 text-[10px] hidden max-m:block"
                >
                  ЗАКАЗЫ
                </div>
                <div class="flex items-center link-to-salary">
                  <svg class="w-4 h-4 mr-2">
                    <use xlink:href="@/assets/i.svg#nav-flow"></use>
                  </svg>
                  <span class="text-base font-latobold">{{ item.count }}</span>
                </div>
              </div>

              <div v-if="item.new">
                <div
                  class="text-gray-400 text-[10px] hidden text-right max-m:block"
                >
                  НАЗНАЧЕНО
                </div>
                <div class="flex items-center link-to-salary">
                  <svg class="fill-status-10 w-4 h-4 mr-2">
                    <use xlink:href="@/assets/i.svg#status-4"></use>
                  </svg>
                  <span class="text-status-10 text-base font-latobold">{{
                    item.new
                  }}</span>
                </div>
              </div>

              <div v-else></div>
              <div v-if="item.solved">
                <div
                  class="text-gray-400 text-[10px] hidden text-right max-m:block"
                >
                  СДЕЛАНО
                </div>
                <div class="flex items-center link-to-salary">
                  <svg class="fill-status-5 w-4 h-4 mr-2">
                    <use xlink:href="@/assets/i.svg#status-4"></use>
                  </svg>
                  <span class="text-status-5 text-base font-latobold">{{
                    item.solved
                  }}</span>
                </div>
              </div>
              <div v-else></div>

              <div v-if="item.confirm">
                <div
                  class="text-gray-400 text-[10px] hidden text-right max-m:block"
                >
                  ОДОБРЕНО
                </div>
                <div class="flex items-center link-to-salary">
                  <svg class="fill-status-8 w-4 h-4 mr-2">
                    <use xlink:href="@/assets/i.svg#status-8"></use>
                  </svg>
                  <span class="text-status-8 text-base font-latobold">{{
                    item.confirm
                  }}</span>
                </div>
              </div>
              <div v-else></div>
            </div>
            <div v-if="item.weekend">
              <div
                class="text-gray-400 text-[10px] hidden text-right max-m:block"
              >
                ВЫХОДНЫЕ
              </div>
              <div class="flex items-center link-to-nothing">
                <svg class="fill-status-3 w-4 h-4 mr-2">
                  <use xlink:href="@/assets/i.svg#nav-calendar"></use>
                </svg>
                <span class="text-status-3 text-base font-latobold">{{
                  item.weekend
                }}</span>
              </div>
            </div>

            <div v-else></div>

            <div
              @click="getSalaryBonuses({ month: month, uid: id })"
              class="hover:shadow-v2"
              style="
                border-radius: 5px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
              "
            >
              <div v-if="+item.one + +item.two + +item.three">
                <div
                  class="text-gray-400 text-[10px] hidden text-right max-m:block"
                >
                  БОНУСЫ
                </div>
                <div class="flex items-center link-to-bonus">
                  <svg class="fill-status-1 w-4 h-4 mr-2">
                    <use xlink:href="@/assets/i.svg#status-6"></use>
                  </svg>
                  <span class="text-status-1 text-base font-latobold">{{
                    +item.one + +item.two + +item.three
                  }}</span>
                </div>
              </div>
              <div v-else></div>

              <div v-if="item.penalty">
                <div
                  class="text-gray-400 text-[10px] hidden text-right max-m:block"
                >
                  ШТРАФЫ
                </div>
                <div class="flex items-center link-to-bonus">
                  <svg class="fill-status-7 w-4 h-4 mr-2">
                    <use xlink:href="@/assets/i.svg#status-11"></use>
                  </svg>
                  <span class="text-status-7 text-base font-latobold">{{
                    item.penalty
                  }}</span>
                </div>
              </div>
              <div v-else></div>
              <div>
                <div
                  class="text-gray-400 text-[10px] hidden text-right max-m:block"
                >
                  ИТОГO
                </div>
                <div class="flex items-center link-to-bonus">
                  <span class="text-base font-latobold">
                    {{
                      (parseInt(item.confirm) || 0) +
                      (parseInt(item.weekend) || 0) +
                      (parseInt(item.penalty) || 0)
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <ul v-if="false">
          <ol v-for="(item, id) in monthContent" :key="id">
            <pre v-text="JSON.stringify(item)" />
            <pre
              v-text="
                JSON.stringify(
                  (selectMasters ?? []).find((m) => m.uid == id) ?? false
                )
              "
            />
          </ol>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import SalaryOrder from "../components/SalaryOrder.vue";
import SalaryBonus from "../components/SalaryBonus.vue";

const monthToString = {
  "01": "Январь",
  "02": "Февраль",
  "03": "Март",
  "04": "Апрель",
  "05": "Май",
  "06": "Июнь",
  "07": "Июль",
  "08": "Авгутс",
  "09": "Сентябрь",
  10: "Октябрь",
  11: "Ноябрь",
  12: "Декабрь",
};

export default {
  name: "PageSalary",

  components: { SalaryOrder, SalaryBonus },

  data() {
    return {
      moment: moment,
      loader: false,
      SelectedMonth: "",
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "selectMonth",
      "selectMasters",
      "selectWorkers",
      "SalaryList",
      "BonusList",
      "salaryOrders",
      "salaryBonuses",
    ]),
    AvailableMonths() {
      var res = [];
      let date = new Date();
      let from = new Date("2021-10-01");
      while (date > from) {
        res.push(date.toJSON().slice(0, 7));
        date.setMonth(date.getMonth() - 1);
      }
      return res;
    },
  },

  methods: {
    ...mapActions([
      "getSalaries",
      "getSalaryOrders",
      "setSalaryOrders",
      "getSalaryBonuses",
      "setSalaryBonuses",
      "reSalary",
      "editOrderId",
      "findSalary",
      "clearSettings",
      "fillSalaries",
    ]),

    pickMonth(data) {
      this.loader = true;
      this.getSalaryOrders(data);
    },

    close() {
      this.loader = false;
      this.getSalaryBonuses(null);
      this.getSalaryOrders(null);
    },

    Clear() {
      this.clearSettings();
      this.fillSalaries();
    },
    monthDisplay(month) {
      let [y, m] = month.split("-");
      return `${monthToString[m]} \`${y.slice(-2)}`;
    },
  },

  async mounted() {
    await this.getSalaries();
    window.DEBUG_SALARY = this;
  },
};
</script>
<style>
.link-to-salary {
  height: 50px;
  padding: 5px;
}

.link-to-bonus {
  height: 50px;
  padding: 5px;
}

.link-to-nothing {
  padding: 5px 10px;
  height: 50px;
  display: flex;
  justify-content: center;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}
</style>
