<template>
    <template v-if="activ">
        <a
            class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
            @click="$emit('close')"
        >
            <span class="text-white mr-2">Закрыть</span>
            <svg class="fill-white w-4 h-4">
                <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
        </a>
        <div class="h-full overflow-y-auto max-m:mt-10">
            <h3 class="mt-4 text-lg font-latobold text-center">АНКЕТА АКТИВАЦИИ</h3>

            <template v-if="activ.status == 2">
                <div class="text-status-10 text-center font-latobold uppercase">ЖДЕТ ПРОВЕРКИ</div>
            </template>

            <template v-if="activ.status == 3">
                <div class="text-status-10 text-center font-latobold uppercase">ЖДЕТ ОТПРАВКИ В МИНТРАНС</div>
            </template>

            <template v-if="activ.status == 4">
                <div class="text-status-1 text-center font-latobold uppercase">В МИНТРАНСЕ</div>
            </template>

            <template v-if="activ.status == 7">
                <div class="text-status-7 text-center font-latobold uppercase">ОТКЛОНЕНА МИНТРАНСОМ И ТРЕБУЕТ ДОРАБОТКИ</div>
            </template>

            <template v-if="activ.status == 8">
                <div class="text-status-8 text-center font-latobold uppercase">ОДОБРЕНА</div>
            </template>

            <!-- ТАХОГРАФ -->
            <template v-if="activ.category_id == 1">
                <template v-if="activ.type == 1">
                    <div class="p-5 flex flex-wrap">
                        <div class="w-1/4 px-4">
                            <h2 class="font-latobold text-lg v-bbgray mb-2">Юридическое лицо</h2>
                            <div class="flex flex-wrap">
                                <div class="flex flex-col my-2 w-full" :class="{ 'text-rose-500': !activ.country }">
                                    <span class="text-gray-400 text-xs">Страна регистрации</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.country">Заполнить</div>
                                    <p v-else>{{ activ.country }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ 'text-rose-500': !activ.cfull }">
                                    <span class="text-gray-400 text-xs">Полное наименование</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.cfull">Заполнить</div>
                                    <p v-else>{{ activ.cfull }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ 'text-rose-500': !activ.cname }">
                                    <span class="text-gray-400 text-xs">Сокращенное наименование</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.cname">Заполнить</div>
                                    <p v-else>{{ activ.cname }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ 'text-rose-500': !activ.cinn }">
                                    <span class="text-gray-400 text-xs">ИНН организации</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.cinn">Заполнить</div>
                                    <p v-else>{{ activ.cinn }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ 'text-rose-500': !activ.cogrn }">
                                    <span class="text-gray-400 text-xs">ОГРН</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.cogrn">Заполнить</div>
                                    <p v-else>{{ activ.cogrn }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ 'text-rose-500': !activ.caddress }">
                                    <span class="text-gray-400 text-xs">Юр. aдрес</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.caddress">Заполнить</div>
                                    <p v-else>{{ activ.caddress }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="w-1/4 px-4">
                            <h2 class="font-latobold text-lg v-bbgray mb-2">Ответственный по МЧД</h2>
                            <div class="flex flex-wrap">
                                <div class="flex flex-col my-2 w-1/3" :class="{ '!text-rose-500': !activ.secondname }">
                                    <span class="text-gray-400 text-xs">Фамилия</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.secondname">Заполнить</div>
                                    <p v-else>{{ activ.secondname }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/3" :class="{ '!text-rose-500': !activ.firstname }">
                                    <span class="text-gray-400 text-xs">Имя</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.firstname">Заполнить</div>
                                    <p v-else>{{ activ.firstname }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/3" :class="{ '!text-rose-500': !activ.middlename }">
                                    <span class="text-gray-400 text-xs">Отчество</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.middlename">Заполнить</div>
                                    <p v-else>{{ activ.middlename }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.sex }">
                                    <span class="text-gray-400 text-xs">Пол</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.sex">Заполнить</div>
                                    <p v-else>{{ activ.sex }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.birthday }">
                                    <span class="text-gray-400 text-xs">Дата рождения</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.birthday">Заполнить</div>
                                    <p v-else>{{ activ.birthday }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.snils }">
                                    <span class="text-gray-400 text-xs">СНИЛС</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.snils">Заполнить</div>
                                    <p v-else>{{ activ.snils }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.inn }">
                                    <span class="text-gray-400 text-xs">ИНН</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.inn">Заполнить</div>
                                    <p v-else>{{ activ.inn }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.address }">
                                    <span class="text-gray-400 text-xs">Адрес (прописка)</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.address">Заполнить</div>
                                    <p v-else>{{ activ.address }}</p>
                                </div>

                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.nationality }">
                                    <span class="text-gray-400 text-xs">Гражданство</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.nationality">Заполнить</div>
                                    <p v-else>{{ activ.nationality }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.doc }">
                                    <span class="text-gray-400 text-xs">Вид документа</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.doc">Заполнить</div>
                                    <p v-else>{{ activ.doc }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pseries }">
                                    <span class="text-gray-400 text-xs">Серия</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pseries">Заполнить</div>
                                    <p v-else>{{ activ.pseries }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pnum }">
                                    <span class="text-gray-400 text-xs">Номер</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pnum">Заполнить</div>
                                    <p v-else>{{ activ.pnum }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.pwho }">
                                    <span class="text-gray-400 text-xs">Кем выдан</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pwho">Заполнить</div>
                                    <p v-else>{{ activ.pwho }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pdate }">
                                    <span class="text-gray-400 text-xs">Дата выдачи</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pdate">Заполнить</div>
                                    <p v-else>{{ activ.pdate }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pcode }">
                                    <span class="text-gray-400 text-xs">Код подраз-ния</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pcode">Заполнить</div>
                                    <p v-else>{{ activ.pcode }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.pplace }">
                                    <span class="text-gray-400 text-xs">Место рождения</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pplace">Заполнить</div>
                                    <p v-else>{{ activ.pplace }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.phone }">
                                    <span class="text-gray-400 text-xs">Телефон</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.phone">Заполнить</div>
                                    <p v-else>{{ activ.phone }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="w-1/4 px-4">
                            <h2 class="font-latobold text-lg v-bbgray mb-2">Транспортное средство</h2>
                            <div class="flex flex-wrap">
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.tcountry }">
                                    <span class="text-gray-400 text-xs">Страна регистрации ТС</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.tcountry">Заполнить</div>
                                    <p v-else>{{ activ.tcountry }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.category }">
                                    <span class="text-gray-400 text-xs">Категория ТС (м1/м2/м3/N1/N2/N3)</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.category">Заполнить</div>
                                    <p v-else>{{ activ.category }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.mark }">
                                    <span class="text-gray-400 text-xs">Марка</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.mark">Заполнить</div>
                                    <p v-else>{{ activ.mark }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.model }">
                                    <span class="text-gray-400 text-xs">Модель</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.model">Заполнить</div>
                                    <p v-else>{{ activ.model }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.year }">
                                    <span class="text-gray-400 text-xs">Год выпуска</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.year">Заполнить</div>
                                    <p v-else>{{ activ.year }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.color }">
                                    <span class="text-gray-400 text-xs">Цвет</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.color">Заполнить</div>
                                    <p v-else>{{ activ.color }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.num }">
                                    <span class="text-gray-400 text-xs">Регистрационный номер</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.num">Заполнить</div>
                                    <p v-else>{{ activ.num }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.vin }">
                                    <span class="text-gray-400 text-xs">VIN</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.vin">Заполнить</div>
                                    <p v-else>{{ activ.vin }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.pts }">
                                    <span class="text-gray-400 text-xs">Документ о регистрации</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pts">Заполнить</div>
                                    <p v-else>{{ activ.pts }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.sts }">
                                    <span class="text-gray-400 text-xs">Паспорт ТС серия, указан в СТС</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.sts">Заполнить</div>
                                    <p v-else>{{ activ.sts }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="w-1/4 px-4">
                            <h2 class="font-latobold text-lg v-bbgray mb-2">Вложения</h2>
                            <div class="flex flex-wrap">
                                <template v-for="i in activ.companyImages" :key="i">
                                    <IcoImg :img="i.img" :status="i.status" />
                                </template>
                                <template v-for="i in activ.contactImages" :key="i">
                                    <IcoImg :img="i.img" :status="i.status" />
                                </template>
                                <template v-for="i in activ.tsImages" :key="i">
                                    <IcoImg :img="i.img" :status="i.status" />
                                </template>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="activ.type == 2">
                    <div class="p-5 flex flex-wrap">
                        <div class="w-1/4 px-4">
                            <h2 class="font-latobold text-lg v-bbgray mb-2">Физическое лицо</h2>
                            <div class="flex flex-wrap">
                                <div class="flex flex-col my-2 w-full" :class="{ 'text-rose-500': !activ.country }">
                                    <span class="text-gray-400 text-xs">Страна регистрации</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.country">Заполнить</div>
                                    <p v-else>{{ activ.country }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="w-1/4 px-4">
                            <h2 class="font-latobold text-lg v-bbgray mb-2">Данные заявителя</h2>
                            <div class="flex flex-wrap">
                                <div class="flex flex-col my-2 w-1/3" :class="{ '!text-rose-500': !activ.secondname }">
                                    <span class="text-gray-400 text-xs">Фамилия</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.secondname">Заполнить</div>
                                    <p v-else>{{ activ.secondname }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/3" :class="{ '!text-rose-500': !activ.firstname }">
                                    <span class="text-gray-400 text-xs">Имя</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.firstname">Заполнить</div>
                                    <p v-else>{{ activ.firstname }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/3" :class="{ '!text-rose-500': !activ.middlename }">
                                    <span class="text-gray-400 text-xs">Отчество</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.middlename">Заполнить</div>
                                    <p v-else>{{ activ.middlename }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.sex }">
                                    <span class="text-gray-400 text-xs">Пол</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.sex">Заполнить</div>
                                    <p v-else>{{ activ.sex }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.birthday }">
                                    <span class="text-gray-400 text-xs">Дата рождения</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.birthday">Заполнить</div>
                                    <p v-else>{{ activ.birthday }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.snils }">
                                    <span class="text-gray-400 text-xs">СНИЛС</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.snils">Заполнить</div>
                                    <p v-else>{{ activ.snils }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.inn }">
                                    <span class="text-gray-400 text-xs">ИНН</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.inn">Заполнить</div>
                                    <p v-else>{{ activ.inn }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.address }">
                                    <span class="text-gray-400 text-xs">Адрес (прописка)</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.address">Заполнить</div>
                                    <p v-else>{{ activ.address }}</p>
                                </div>

                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.nationality }">
                                    <span class="text-gray-400 text-xs">Гражданство</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.nationality">Заполнить</div>
                                    <p v-else>{{ activ.nationality }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.doc }">
                                    <span class="text-gray-400 text-xs">Вид документа</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.doc">Заполнить</div>
                                    <p v-else>{{ activ.doc }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pseries }">
                                    <span class="text-gray-400 text-xs">Серия</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pseries">Заполнить</div>
                                    <p v-else>{{ activ.pseries }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pnum }">
                                    <span class="text-gray-400 text-xs">Номер</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pnum">Заполнить</div>
                                    <p v-else>{{ activ.pnum }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.pwho }">
                                    <span class="text-gray-400 text-xs">Кем выдан</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pwho">Заполнить</div>
                                    <p v-else>{{ activ.pwho }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pdate }">
                                    <span class="text-gray-400 text-xs">Дата выдачи</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pdate">Заполнить</div>
                                    <p v-else>{{ activ.pdate }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pcode }">
                                    <span class="text-gray-400 text-xs">Код подраз-ния</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pcode">Заполнить</div>
                                    <p v-else>{{ activ.pcode }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.pplace }">
                                    <span class="text-gray-400 text-xs">Место рождения</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pplace">Заполнить</div>
                                    <p v-else>{{ activ.pplace }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.phone }">
                                    <span class="text-gray-400 text-xs">Телефон</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.phone">Заполнить</div>
                                    <p v-else>{{ activ.phone }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="w-1/4 px-4">
                            <h2 class="font-latobold text-lg v-bbgray mb-2">Транспортное средство</h2>
                            <div class="flex flex-wrap">
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.tcountry }">
                                    <span class="text-gray-400 text-xs">Страна регистрации ТС</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.tcountry">Заполнить</div>
                                    <p v-else>{{ activ.tcountry }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.category }">
                                    <span class="text-gray-400 text-xs">Категория ТС (м1/м2/м3/N1/N2/N3)</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.category">Заполнить</div>
                                    <p v-else>{{ activ.category }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.mark }">
                                    <span class="text-gray-400 text-xs">Марка</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.mark">Заполнить</div>
                                    <p v-else>{{ activ.mark }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.model }">
                                    <span class="text-gray-400 text-xs">Модель</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.model">Заполнить</div>
                                    <p v-else>{{ activ.model }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.year }">
                                    <span class="text-gray-400 text-xs">Год выпуска</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.year">Заполнить</div>
                                    <p v-else>{{ activ.year }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.color }">
                                    <span class="text-gray-400 text-xs">Цвет</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.color">Заполнить</div>
                                    <p v-else>{{ activ.color }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.num }">
                                    <span class="text-gray-400 text-xs">Регистрационный номер</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.num">Заполнить</div>
                                    <p v-else>{{ activ.num }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.vin }">
                                    <span class="text-gray-400 text-xs">VIN</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.vin">Заполнить</div>
                                    <p v-else>{{ activ.vin }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.pts }">
                                    <span class="text-gray-400 text-xs">Документ о регистрации</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pts">Заполнить</div>
                                    <p v-else>{{ activ.pts }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.sts }">
                                    <span class="text-gray-400 text-xs">Паспорт ТС серия, указан в СТС</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.sts">Заполнить</div>
                                    <p v-else>{{ activ.sts }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="w-1/4 px-4">
                            <h2 class="font-latobold text-lg v-bbgray mb-2">Вложения</h2>
                            <div class="flex flex-wrap">
                                <template v-for="i in activ.companyImages" :key="i">
                                    <IcoImg :img="i.img" :status="i.status" />
                                </template>
                                <template v-for="i in activ.contactImages" :key="i">
                                    <IcoImg :img="i.img" :status="i.status" />
                                </template>
                                <template v-for="i in activ.tsImages" :key="i">
                                    <IcoImg :img="i.img" :status="i.status" />
                                </template>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="activ.type == 3">
                    <div class="p-5 flex flex-wrap">
                        <div class="w-1/4 px-4">
                            <h2 class="font-latobold text-lg v-bbgray mb-2">Данные ИП</h2>
                            <div class="flex flex-wrap">
                                <div class="flex flex-col my-2 w-full" :class="{ 'text-rose-500': !activ.ipsecondname }">
                                    <span class="text-gray-400 text-xs">Фамилия</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.ipsecondname">Заполнить</div>
                                    <p v-else>{{ activ.ipsecondname }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ 'text-rose-500': !activ.ipname }">
                                    <span class="text-gray-400 text-xs">Имя</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.ipname">Заполнить</div>
                                    <p v-else>{{ activ.ipname }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ 'text-rose-500': !activ.ipmiddlename }">
                                    <span class="text-gray-400 text-xs">Отчество</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.ipmiddlename">Заполнить</div>
                                    <p v-else>{{ activ.ipmiddlename }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.ipsex }">
                                    <span class="text-gray-400 text-xs">Пол</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.ipsex">Заполнить</div>
                                    <p v-else>{{ activ.ipsex }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.ipbirthday }">
                                    <span class="text-gray-400 text-xs">Дата рождения</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.ipbirthday">Заполнить</div>
                                    <p v-else>{{ activ.ipbirthday }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.ipsnils }">
                                    <span class="text-gray-400 text-xs">СНИЛС</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.ipsnils">Заполнить</div>
                                    <p v-else>{{ activ.ipsnils }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.cinn }">
                                    <span class="text-gray-400 text-xs">ИНН</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.cinn">Заполнить</div>
                                    <p v-else>{{ activ.cinn }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ 'text-rose-500': !activ.cogrn }">
                                    <span class="text-gray-400 text-xs">ОГРНИП</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.cogrn">Заполнить</div>
                                    <p v-else>{{ activ.cogrn }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.caddress }">
                                    <span class="text-gray-400 text-xs">Адрес (прописка)</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.caddress">Заполнить</div>
                                    <p v-else>{{ activ.caddress }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="w-1/4 px-4">
                            <h2 class="font-latobold text-lg v-bbgray mb-2">Ответственный по МЧД</h2>
                            <div class="flex flex-wrap">
                                <div class="flex flex-col my-2 w-1/3" :class="{ '!text-rose-500': !activ.secondname }">
                                    <span class="text-gray-400 text-xs">Фамилия</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.secondname">Заполнить</div>
                                    <p v-else>{{ activ.secondname }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/3" :class="{ '!text-rose-500': !activ.firstname }">
                                    <span class="text-gray-400 text-xs">Имя</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.firstname">Заполнить</div>
                                    <p v-else>{{ activ.firstname }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/3" :class="{ '!text-rose-500': !activ.middlename }">
                                    <span class="text-gray-400 text-xs">Отчество</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.middlename">Заполнить</div>
                                    <p v-else>{{ activ.middlename }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.sex }">
                                    <span class="text-gray-400 text-xs">Пол</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.sex">Заполнить</div>
                                    <p v-else>{{ activ.sex }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.birthday }">
                                    <span class="text-gray-400 text-xs">Дата рождения</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.birthday">Заполнить</div>
                                    <p v-else>{{ activ.birthday }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.snils }">
                                    <span class="text-gray-400 text-xs">СНИЛС</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.snils">Заполнить</div>
                                    <p v-else>{{ activ.snils }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.inn }">
                                    <span class="text-gray-400 text-xs">ИНН</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.inn">Заполнить</div>
                                    <p v-else>{{ activ.inn }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.address }">
                                    <span class="text-gray-400 text-xs">Адрес (прописка)</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.address">Заполнить</div>
                                    <p v-else>{{ activ.address }}</p>
                                </div>

                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.nationality }">
                                    <span class="text-gray-400 text-xs">Гражданство</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.nationality">Заполнить</div>
                                    <p v-else>{{ activ.nationality }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.doc }">
                                    <span class="text-gray-400 text-xs">Вид документа</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.doc">Заполнить</div>
                                    <p v-else>{{ activ.doc }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pseries }">
                                    <span class="text-gray-400 text-xs">Серия</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pseries">Заполнить</div>
                                    <p v-else>{{ activ.pseries }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pnum }">
                                    <span class="text-gray-400 text-xs">Номер</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pnum">Заполнить</div>
                                    <p v-else>{{ activ.pnum }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.pwho }">
                                    <span class="text-gray-400 text-xs">Кем выдан</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pwho">Заполнить</div>
                                    <p v-else>{{ activ.pwho }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pdate }">
                                    <span class="text-gray-400 text-xs">Дата выдачи</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pdate">Заполнить</div>
                                    <p v-else>{{ activ.pdate }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pcode }">
                                    <span class="text-gray-400 text-xs">Код подраз-ния</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pcode">Заполнить</div>
                                    <p v-else>{{ activ.pcode }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.pplace }">
                                    <span class="text-gray-400 text-xs">Место рождения</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pplace">Заполнить</div>
                                    <p v-else>{{ activ.pplace }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.phone }">
                                    <span class="text-gray-400 text-xs">Телефон</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.phone">Заполнить</div>
                                    <p v-else>{{ activ.phone }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="w-1/4 px-4">
                            <h2 class="font-latobold text-lg v-bbgray mb-2">Транспортное средство</h2>
                            <div class="flex flex-wrap">
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.tcountry }">
                                    <span class="text-gray-400 text-xs">Страна регистрации ТС</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.tcountry">Заполнить</div>
                                    <p v-else>{{ activ.tcountry }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.category }">
                                    <span class="text-gray-400 text-xs">Категория ТС (м1/м2/м3/N1/N2/N3)</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.category">Заполнить</div>
                                    <p v-else>{{ activ.category }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.mark }">
                                    <span class="text-gray-400 text-xs">Марка</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.mark">Заполнить</div>
                                    <p v-else>{{ activ.mark }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.model }">
                                    <span class="text-gray-400 text-xs">Модель</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.model">Заполнить</div>
                                    <p v-else>{{ activ.model }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.year }">
                                    <span class="text-gray-400 text-xs">Год выпуска</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.year">Заполнить</div>
                                    <p v-else>{{ activ.year }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.color }">
                                    <span class="text-gray-400 text-xs">Цвет</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.color">Заполнить</div>
                                    <p v-else>{{ activ.color }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.num }">
                                    <span class="text-gray-400 text-xs">Регистрационный номер</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.num">Заполнить</div>
                                    <p v-else>{{ activ.num }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.vin }">
                                    <span class="text-gray-400 text-xs">VIN</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.vin">Заполнить</div>
                                    <p v-else>{{ activ.vin }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.pts }">
                                    <span class="text-gray-400 text-xs">Документ о регистрации</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.pts">Заполнить</div>
                                    <p v-else>{{ activ.pts }}</p>
                                </div>
                                <div class="flex flex-col my-2 w-full" :class="{ '!text-rose-500': !activ.sts }">
                                    <span class="text-gray-400 text-xs">Паспорт ТС серия, указан в СТС</span>
                                    <div class="text-rose-500 uppercase" v-if="!activ.sts">Заполнить</div>
                                    <p v-else>{{ activ.sts }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="w-1/4 px-4">
                            <h2 class="font-latobold text-lg v-bbgray mb-2">Вложения</h2>
                            <div class="flex flex-wrap">
                                <template v-for="i in activ.companyImages" :key="i">
                                    <IcoImg :img="i.img" :status="i.status" />
                                </template>
                                <template v-for="i in activ.contactImages" :key="i">
                                    <IcoImg :img="i.img" :status="i.status" />
                                </template>
                                <template v-for="i in activ.tsImages" :key="i">
                                    <IcoImg :img="i.img" :status="i.status" />
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </template>

            <!-- МОНИТОРИНГ -->
            <template v-if="activ.category_id == 2">
                <div class="flex flex-wrap max-m:block">
                    <div class="w-2/3 px-4 max-m:w-full">
                        <h2 class="font-latobold text-lg v-bbgray mb-2">Транспортное средство</h2>
                        <div class="flex flex-wrap">
                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.category }">
                                <span class="text-gray-400 text-xs">Категория ТС (м1/м2/м3/N1/N2/N3)</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.category">Заполнить</div>
                                <p v-else>{{ activ.category }}</p>
                            </div>
                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.mark }">
                                <span class="text-gray-400 text-xs">Марка</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.mark">Заполнить</div>
                                <p v-else>{{ activ.mark }}</p>
                            </div>
                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.model }">
                                <span class="text-gray-400 text-xs">Модель</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.model">Заполнить</div>
                                <p v-else>{{ activ.model }}</p>
                            </div>
                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.year }">
                                <span class="text-gray-400 text-xs">Год выпуска</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.year">Заполнить</div>
                                <p v-else>{{ activ.year }}</p>
                            </div>
                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.color }">
                                <span class="text-gray-400 text-xs">Цвет</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.color">Заполнить</div>
                                <p v-else>{{ activ.color }}</p>
                            </div>
                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.num }">
                                <span class="text-gray-400 text-xs">Регистрационный номер</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.num">Заполнить</div>
                                <p v-else>{{ activ.num }}</p>
                            </div>
                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.vin }">
                                <span class="text-gray-400 text-xs">VIN</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.vin">Заполнить</div>
                                <p v-else>{{ activ.vin }}</p>
                            </div>
                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.pts }">
                                <span class="text-gray-400 text-xs">Документ о регистрации</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.pts">Заполнить</div>
                                <p v-else>{{ activ.pts }}</p>
                            </div>
                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.sts }">
                                <span class="text-gray-400 text-xs">Паспорт ТС серия, указан в СТС</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.sts">Заполнить</div>
                                <p v-else>{{ activ.sts }}</p>
                            </div>

                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.state }">
                                <span class="text-gray-400 text-xs">Состояние ТС</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.state">Заполнить</div>
                                <p v-else>
                                    {{
                                        activ.state == 1
                                            ? "Работает"
                                            : activ.state == 2
                                            ? "В ремонте"
                                            : activ.state == 3
                                            ? "Неисправна"
                                            : activ.state == 4
                                            ? "Конструктивные особенности"
                                            : ""
                                    }}
                                </p>
                            </div>

                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.maxspeed }">
                                <span class="text-gray-400 text-xs">Максимальная скорость</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.maxspeed">Заполнить</div>
                                <p v-else>{{ activ.maxspeed }}</p>
                            </div>

                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.fuel }">
                                <span class="text-gray-400 text-xs">Вид топлива</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.fuel">Заполнить</div>
                                <p v-else>
                                    {{ activ.fuel == 1 ? "Газ" : activ.fuel == 2 ? "Бензин" : activ.fuel == 3 ? "ДТ" : "" }}
                                </p>
                            </div>

                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.tank }">
                                <span class="text-gray-400 text-xs">Кол-во баков</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.tank">Заполнить</div>
                                <p v-else>{{ activ.tank }}</p>
                            </div>

                            <div class="flex flex-col my-2 w-1/2" :class="{ '!text-rose-500': !activ.val }">
                                <span class="text-gray-400 text-xs">Объем бака в л</span>
                                <div class="text-rose-500 uppercase" v-if="!activ.val">Заполнить</div>
                                <p v-else>{{ activ.val }}</p>
                            </div>

                            <div class="flex flex-col my-2 w-1/2 max-m:w-full">
                                <input class="mychbox" id="warr" type="checkbox" disabled v-model="activ.warranty" />
                                <label for="warr">Гарантийное ТС</label>
                            </div>

                            <div class="flex flex-col my-2 w-1/2 max-m:w-full">
                                <input class="mychbox" id="warr" type="checkbox" disabled v-model="activ.shape" />
                                <label for="warr">Нестандартная форма баков</label>
                            </div>

                            <div class="flex flex-col my-2 w-1/2 max-m:w-full">
                                <input class="mychbox" id="warr" type="checkbox" disabled v-model="activ.flow" />
                                <label for="warr">Наличие "перетекайки"</label>
                            </div>

                            <div class="flex flex-col my-2 w-1/2 max-m:w-full">
                                <input class="mychbox" id="warr" type="checkbox" disabled v-model="activ.block" />
                                <label for="warr">Блокировка "перетекайки"</label>
                            </div>
                        </div>

                        <h2 class="font-latobold text-lg v-bbgray mb-2 mt-6">Уведомления</h2>
                        <div class="flex flex-wrap">
                            <div class="flex flex-col my-2 w-1/2 max-m:w-full">
                                <input class="mychbox" id="warr" type="checkbox" disabled v-model="activ.notice_speed" />
                                <label for="warr">Скорость</label>
                            </div>
                            <div class="flex flex-col my-2 w-1/2 max-m:w-full">
                                <input class="mychbox" id="warr" type="checkbox" disabled v-model="activ.notice_hours" />
                                <label for="warr">График работы</label>
                            </div>
                            <div class="flex flex-col my-2 w-1/2 max-m:w-full">
                                <input class="mychbox" id="warr" type="checkbox" disabled v-model="activ.notice_zone" />
                                <label for="warr">Геозона</label>
                            </div>
                            <div class="flex flex-col my-2 w-1/2 max-m:w-full">
                                <input class="mychbox" id="warr" type="checkbox" disabled v-model="activ.notice_fuel" />
                                <label for="warr">Слив топлива</label>
                            </div>
                            <div class="flex flex-col my-2 w-full">
                                <span class="text-gray-400 text-xs">Другое</span>
                                <p>{{ activ.notice_other }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="w-1/4 px-4 mt-10 max-m:w-full">
                        <h2 class="font-latobold text-lg v-bbgray mb-2">Вложения</h2>
                        <div class="flex flex-wrap">
                            <template v-for="i in activ.companyImages" :key="i">
                                <IcoImg :img="i.img" :status="i.status" />
                            </template>
                            <template v-for="i in activ.contactImages" :key="i">
                                <IcoImg :img="i.img" :status="i.status" />
                            </template>
                            <template v-for="i in activ.tsImages" :key="i">
                                <IcoImg :img="i.img" :status="i.status" />
                            </template>

                        </div>
                    </div>
                </div>
            </template>
            <div v-if="activ.status < 8" class="p-5 v-bbgray">
                <CommentList :id="activ.id" :iid="null" type="activation" />
            </div>

            <div v-if="activ.status == 2 && ['Manager', 'Studio'].includes(role)" class="p-5 flex justify-center">
                <template v-if="activ.category_id == 1">
                    <button class="mx-2 v-btn bg-green-500 hover:bg-green-400 transition-colors duration-150" @click="ActivStatus(3), $emit('close')">
                        <span class="v-btn_text">ПОДТВЕРДИТЬ</span>
                    </button>
                </template>
                <template v-if="activ.category_id == 2">
                    <button
                        class="mx-2 v-btn bg-green-500 hover:bg-green-400 transition-colors duration-150"
                        @click="doneActivation(), $emit('close')"
                    >
                        <span class="v-btn_text">ПОДТВЕРДИТЬ</span>
                    </button>
                </template>
                <button class="mx-2 v-btn bg-rose-500 hover:bg-rose-400 transition-colors duration-150" @click="ActivStatus(1), $emit('close')">
                    <span class="v-btn_text">ОТКЛОНИТЬ</span>
                </button>
            </div>

            <div v-if="role == 'Logist'" class="p-5 flex justify-center">
                <template v-if="activ.status == 3">
                    <button class="mx-2 v-btn bg-green-500 hover:bg-green-400 transition-colors duration-150" @click="ActivStatus(4), $emit('close')">
                        <span class="v-btn_text">ОПРАВЛЕНО В МИНТРАНС</span>
                    </button>
                </template>

                <template v-if="activ.status == 4">
                    <button
                        class="mx-2 v-btn bg-green-500 hover:bg-green-400 transition-colors duration-150"
                        @click="doneActivation(), $emit('close')"
                    >
                        <span class="v-btn_text">МИНТРАНС ОДОБРИЛ</span>
                    </button>
                    <button
                        class="mx-2 v-btn bg-rose-500 hover:bg-rose-400 transition-colors duration-150"
                        @click="cancelActivation(3), $emit('close')"
                    >
                        <span class="v-btn_text">МИНТРАНС ОТКЛОНИЛ</span>
                    </button>
                </template>

                <template v-if="activ.status < 6">
                    <button
                        class="mx-2 v-btn bg-rose-500 hover:bg-rose-400 transition-colors duration-150"
                        @click="cancelActivation(1), $emit('close')"
                    >
                        <span class="v-btn_text">НЕКОРРЕКТНАЯ АНКЕТА</span>
                    </button>
                    <button
                        class="mx-2 v-btn bg-rose-500 hover:bg-rose-400 transition-colors duration-150"
                        @click="cancelActivation(2), $emit('close')"
                    >
                        <span class="v-btn_text">СМЕНИЛСЯ МЧД</span>
                    </button>
                </template>

                <button class="mx-2 v-btn bg-rose-500 hover:bg-rose-400 transition-colors duration-150" @click="overdueActivation(), $emit('close')">
                    <span class="v-btn_text">АНКЕТА ИСТЕКЛА</span>
                </button>
            </div>
        </div>
    </template>

    <div v-else class="loader opacity1 mt30">
        <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CommentList from "./CommentList.vue";
export default {
    name: "ActivView",

    components: { CommentList },

    emits: ["close"],

    computed: {
        ...mapGetters(["role", "client", "activ"]),
    },

    methods: {
        ...mapActions(["ActivStatus", "cancelActivation", "doneActivation", "overdueActivation", "delImg"]),
        UploadAgreement(e){
            console.log('EVENT', e);
            this.activ.agreementImages.push(...e.data.picture)
        },
    },

    mounted() {
        window.DEBUG_ACTIVE_VIEW = this;
    },
};
</script>
