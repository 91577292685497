<template>
	<div class="flex">
		<div class="min-w-[300px] w-40p mr-4">
			<template v-if="claim.order?.id"><ClaimOrder :order="claim.order" type="Основной заказ" /> </template>

			<template v-if="claim.od?.id"> <ClaimOrder :order="claim.od" type="Диагностика" /> </template>

			<template v-if="claim.os?.id"> <ClaimOrder :order="claim.os" type="Решение" /> </template>
		</div>

		<div class="flex-1 my-4">
			<div class="bg-white rounded-lg">
				<div class="p-5 v-bbgray">
					<div class="relative">
						<h2 class="text-center flex flex-col">
							<span class="text-[11px] text-gray-400 leading-none uppercase mb-2">{{ st[claim.status] }}</span>
							<span class="font-latobold text-xl mb-2">РЕКЛАМАЦИЯ № {{ claim.iid }}</span>
							<span class="text-[11px] text-gray-400 leading-none uppercase mb-2"> от {{ moment(claim.create_at).format('DD.MM.YYYY') }}</span>
							<span class="text-[11px] text-gray-400 leading-none uppercase mb-2">На основании заказа № {{ claim.order?.iid }} от {{ moment(claim.order?.create_at).format('DD.MM.YYYY') }}</span>
							<span class="font-latobold text-xl">{{ days }}</span>
						</h2>

						<div class="absolute top-2 -right-5 h-14 overflow-hidden max-w-[50%] px-6 py-3 flex items-center">
							<svg class="w-7 h-7 fill-white z-2 mr-2">
								<use xlink:href="@/assets/i.svg#warranty"></use>
							</svg>
							<template v-if="claim.warranty == 1">
								<span id="timer" class="text-white z-20 relative text-lg font-latobold">ГАРАНТИЯ</span>
								<svg class="absolute top-0 -left-8 h-full fill-emerald-600"><use xlink:href="@/assets/bg.svg#bg-timer"></use></svg>
							</template>
							<template v-else>
								<span id="timer" class="text-white z-20 relative text-lg font-latobold">ПЛАТНО</span>
								<svg class="absolute top-0 -left-8 h-full fill-rose-500"><use xlink:href="@/assets/bg.svg#bg-timer"></use></svg>
							</template>
						</div>
					</div>
				</div>

				<div class="p-5 v-bbgray flex flex-wrap">
					<div class="w-3/5 flex flex-col px-4 mb-4">
						<label class="text-gray-400">Выбрать контакт</label>
						<select v-model="claim.contact_id" @change="saveClaim(claim)" class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700">
							<option>Выбрать</option>
							<template v-for="c in claim.client?.contacts" :key="c">
								<option v-if="claim.contact_id == c.id" selected :value="c.id">{{ c.secondname }} {{ c.name }} {{ c.phone }}</option>
								<option v-else :value="c.id">{{ c.secondname }} {{ c.name }} {{ c.phone }}</option>
							</template>
						</select>
					</div>

					<!-- <div class="fgrp cox100 col33 pr15 mb10">
                    <label>Или создать новый</label>
                    <a href="/client/view/{{ claim.order.client.iid }}" target="_blank" class="btn w100">Создать</a>
                </div> -->

					<div class="w-3/5 flex flex-col px-4 mb-4">
						<label class="text-gray-400">Способ обращения</label>
						<select v-model="claim.call_type" @change="saveClaim(claim)" class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700">
							<option>Выбрать</option>
							<option value="1">Телефон</option>
							<option value="2">Почта</option>
							<option value="3">Скайп</option>
							<option value="4">Whatsapp</option>
							<option value="5">Приехал в офис</option>
							<option value="5">Отзыв и интернете</option>
						</select>
					</div>

					<div class="w-2/5 flex flex-col px-4 mb-4">
						<label class="text-gray-400">Дата обращения</label>
						<Datepicker v-model="claim.call_at" format="dd.MM.yyyy HH:mm" @update:modelValue="saveClaim(claim)" />
					</div>

					<div class="w-2/5 flex flex-col px-4 mb-4">
						<label class="text-gray-400">Тема</label>
						<select v-model="claim.theme" @change="saveClaim(claim)" class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700">
							<option>Выбрать</option>
							<option value="1">Тахографы</option>
							<option value="2">Мониторинг</option>
							<option value="3">Карты</option>
							<option value="4">Пропуски</option>
							<option value="5">Обучение</option>
							<option value="6">МАП</option>
							<option value="7">Отопитель</option>
						</select>
					</div>

					<div class="w-3/5 flex flex-col px-4 mb-4">
						<label class="text-gray-400">Суть обращения</label>
						<select v-model="claim.problem_id" @change="saveClaim(claim)" class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700">
							<option>Выбрать</option>
							<template v-for="(p, key) in problem[claim.theme]" :key="p">
								<option :value="key">{{ p }}</option>
							</template>
						</select>
					</div>

					<div class="w-full flex flex-col px-4">
						<label class="text-gray-400">Комментарий</label>
						<input v-model="claim.problem" @change="saveClaim(claim)" class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700" />
					</div>

					<template v-if="role == 'Check'">
						<template v-if="claim.fault">
							<div class="flex items-center mx-auto mt-4 text-base font-latobold" id="fault">
								<h3 v-if="claim.fault == 1" class="text-rose-500 mr-4">Вина инженера</h3>
								<h3 v-if="claim.fault == 2" class="text-sky-500 mr-4">Другая причина</h3>
								<a @click="faultClaim(0)">
									<svg class="w-5 h-5 hover:fill-red-500 transition-colors duration-150 cursor-pointer">
										<use xlink:href="@/assets/i.svg#ico-no"></use>
									</svg>
								</a>
							</div>
						</template>
						<template v-else>
							<div class="flex px-4 mt-4 mx-auto">
								<button class="mx-2 v-btn bg-rose-500 hover:bg-rose-400 transition-colors duration-150 text-white uppercase" @click="faultClaim(1)">Вина инженера</button>
								<button class="mx-2 v-btn bg-sky-500 hover:bg-sky-400 transition-colors duration-150 text-white uppercase" @click="faultClaim(2)">Другая причина</button>
							</div>
						</template>
					</template>
				</div>
			</div>

			<div class="p-5 bg-white rounded-lg mt-4">
				<h3 class="text-center font-latobold mb-2">ДИАГНОСТИКА</h3>

				<template v-if="claim.diagnosis_order_id">
					<div class="ctr">
						<template v-if="[1, 2, 3, 4, 5].includes(parseInt(claim.od?.status))">
							<h3 v-if="claim.diagnosis_type == 2" class="text-center font-latobold mb-2 text-rose-500">Заказана платная диагностика</h3>
							<h3 v-else class="text-center font-latobold mb-2 text-green-500">Заказана бесплатная диагностика</h3>
						</template>
						<template v-if="[6, 8].includes(parseInt(claim.od?.status))">
							<h3 v-if="claim.diagnosis_type == 2" class="text-center font-latobold mb-2 text-rose-500">Завершена платная диагностика</h3>
							<h3 v-else class="text-center font-latobold mb-2 text-green-500">Завершена бесплатная диагностика</h3>
						</template>
					</div>
					<template v-if="claim.od">
						<CommentList :id="claim.od.id" :iid="claim.od.iid" type="order" />
					</template>
				</template>
				<template v-else>
					<template v-if="['Logist', 'Studio', 'Check'].includes(role) && selectPrices">
						<div class="flex items-center mb-4">
							<p class="text-gray-400 leading-none max-w-[25%] w-full">Добавить услугу</p>
							<div class="flex flex-1 h-9 relative">
								<input type="text" v-model.trim="text_price" class="px-5 py-2 text-emerald-700 w-full bg-white v-bgray" @input="findPrice" @click="findPrice" />
								<div v-if="priceList.length" class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0">
									<div v-for="p in priceList.slice(0, 10)" :key="p.id" @click="pickClaimPrice(p)" class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300">
										<span> {{ p.name }} </span>
										<span class="text-xs">{{ p.sum }}₽</span>
									</div>
								</div>
							</div>
						</div>
						<div class="flex justify-center">
							<a class="mx-2 v-btn v-btn--md bg-green-500 hover:bg-green-400 transition-colors duration-150 text-white uppercase v-btn_text" @click="setClaimDiagnos({ price_id: price_id, warranty: 1 })"> Гарантийная диагностика </a>
							<a class="mx-2 v-btn v-btn--md bg-rose-500 hover:bg-rose-400 transition-colors duration-150 text-white uppercase v-btn_text" @click="setClaimDiagnos({ price_id: price_id, warranty: 2 })"> Платная диагностика </a>
						</div>
					</template>
				</template>
			</div>

			<div class="p-5 bg-white rounded-lg mt-4">
				<h3 class="text-center font-latobold mb-2">РЕШЕНИЕ</h3>

				<template v-if="claim.os">
					<div class="ctr">
						<template v-if="[1, 2, 3, 4, 5].includes(parseInt(claim.os.status))">
							<h3 v-if="claim.solution_type == 2" class="text-center font-latobold mb-2 text-rose-500">Заказано платное решение</h3>
							<h3 v-else class="text-center font-latobold mb-2 text-green-500">Заказано бесплатное решение</h3>
						</template>
						<template v-if="[6, 8].includes(parseInt(claim.os.status))">
							<h3 v-if="claim.solution_type == 2" class="text-center font-latobold mb-2 text-rose-500">Решено (платно)</h3>
							<h3 v-else class="text-center font-latobold mb-2 text-green-500">Решено (бесплатно)</h3>
						</template>
					</div>
					<template v-if="claim.os">
						<CommentList :id="claim.os.id" :iid="claim.os.iid" type="order" />
					</template>
				</template>
				<template v-else>
					<div v-if="claim.status < 6" class="flex justify-center">
						<button class="mx-2 v-btn v-btn--md flex justify-center items-center bg-green-500 hover:bg-green-400 transition-colors duration-150" @click="setClaimSolution({ warranty: 1 })">
							<span class="v-btn_text">Гарантийное решение</span>
						</button>
						<button class="mx-2 v-btn v-btn--md flex justify-center items-center bg-rose-500 hover:bg-rose-400 transition-colors duration-150" @click="setClaimSolution({ warranty: 2 })">
							<span class="v-btn_text">Платное решение</span>
						</button>
					</div>
				</template>
			</div>

			<template v-if="role == 'Check'">
				<div class="p-5 bg-white rounded-lg mt-4">
					<h3 class="text-center font-latobold mb-2">РЕЗУЛЬТАТ</h3>
					<div class="flex items-center">
						<input v-if="parseInt(claim.status) < 6" class="text-emerald-700 bg-white v-bgray h-9 w-full px-4 py-2" type="text" v-model="claim.solution" @change="saveClaim(claim)" />
						<p v-else>{{ claim.solution }}</p>
					</div>
				</div>

				<div class="flex justify-center mt-4" v-if="parseInt(claim.status) < 6">
					<button class="mx-2 v-btn flex justify-center items-center bg-rose-500 hover:bg-rose-400 transition-colors duration-150" @click="delClaim()">
						<svg class="fill-white w-4 h-4 mr-3"><use xlink:href="@/assets/i.svg#ico-delete"></use></svg>
						<span class="v-btn_text">Удалить</span>
					</button>
					<button class="mx-2 v-btn flex justify-center items-center bg-green-500 hover:bg-green-400 transition-colors duration-150" @click="doneClaim()">
						<svg class="fill-white w-4 h-4 mr-3"><use xlink:href="@/assets/i.svg#status-6"></use></svg>
						<span class="v-btn_text">Завершить</span>
					</button>
				</div>
			</template>
		</div>

		<div id="popup" class="cox100 col95"></div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import ClaimOrder from '../components/ClaimOrder.vue';
import CommentList from '../components/CommentList.vue';

export default {
	name: 'ClaimView',

	components: { Datepicker, ClaimOrder, CommentList },

	data() {
		return {
			moment: moment,
			text_contact: '',
			text_price: '',
			price_id: '',
			contactList: [],
			priceList: [],
			st: {
				1: 'новая',
				8: 'проверена КК',
			},
			ico: {
				1: 'status-1',
				3: 'status-3',
				4: 'status-4',
				5: 'status-5',
				6: 'status-6',
				7: 'status-1',
				8: 'status-8',
				10: 'status-1',
				12: 'doc',
				13: 'doc',
				18: 'status-1',
				20: 'status-20',
			},
			color: {
				1: 'bbu',
				3: 'bbd',
				4: 'bbd',
				5: 'bgn',
				6: 'bgd',
				7: 'brd',
				8: 'bgd',
				10: 'bor',
				12: 'bpr',
				13: 'bpr',
				18: 'brd',
				20: 'bgy',
			},
			problem: {
				1: {
					1: 'не принимает карту водителя',
					2: 'не правильно показывает время/дату',
					3: 'отказ/ошибка НКМ',
					4: 'ошибка №...',
					5: 'рассинхронизация данных',
					6: 'нарушение безопасности',
					7: 'дисплей тах не работает',
					8: 'потух тах',
					9: 'завис тах',
					10: 'не работает тах',
					11: 'пломбы не поставили',
					12: 'клавиатура не работает',
					13: 'не корректно считает время труда/отдыха',
					14: 'ДС не работает',
					15: 'расхождение между тах и спидометром',
					16: 'ДРУГОЕ (комментарий)',
				},
				2: {
					21: 'не отслеживается ТС',
					22: 'не корректно показывает местонахождение ТС',
					23: 'ДУТ неисправен',
					24: 'терминал неисправен',
					25: 'датчик температуры неисправен',
					26: 'контрольные заправки нужно произвести',
					27: 'другое (комментарий)',
				},
				3: {
					31: 'ввод недействительной карты',
					32: 'не принимает тах карту',
					33: 'ошибка в перональных данных',
					34: 'фото другого человека',
					35: 'адрес указан не верно',
					36: 'долго делали карту',
					37: 'другое (комментарий)',
				},
				4: {
					41: 'допущена ошибка в выборе зоны действия пропуска',
					42: 'клиент предоставил некорректные документы',
					43: 'долго оказывали услугу',
					44: 'другое (комментарий)',
				},
				5: {
					51: 'обращений не было ни разу',
					52: 'другое (комментарий)',
				},
				7: {
					71: 'Не работает отопитель',
					72: 'другое (комментарий)',
				},
			},
			popup_oper: false,
		};
	},

	computed: {
		...mapGetters(['s', 'profile', 'role', 'roles', 'claim', 'selectClaimContacts', 'selectPrices']),

		days() {
			let count = Math.round((moment() - moment(this.claim.order?.create_at)) / 86400000);
			return this.pluralize(count, ['день', 'дня', 'дней']);
		},
	},

	methods: {
		...mapActions(['getClaim', 'saveClaim', 'doneClaim', 'delClaim', 'setClaimDiagnos', 'setClaimSolution']),

		pluralize(count, words) {
			var cases = [2, 0, 1, 1, 1, 2];
			return count + ' ' + words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]];
		},

		// КОНТАКТ
		findContact() {
			// console.log("findContact: ", this.text_contact);
			if (this.text_contact != '') {
				this.contactList = this.selectClaimContacts.filter((item) => {
					console.log('item', item);
					let name = null;
					let secondname = null;
					if (item.name != null) {
						name = item.name.toLowerCase().indexOf(this.text_contact.toLowerCase()) !== -1;
					}
					if (item.secondname != null) {
						secondname = item.secondname.toLowerCase().indexOf(this.text_contact.toLowerCase()) !== -1;
					}
					if (name || secondname) {
						return true;
					}
					return false;
				});
			} else {
				this.contactList = this.selectClaimContacts;
			}
		},

		// УСЛУГА
		findPrice() {
			console.log('findPrice');
			this.priceList = this.selectPrices.filter((item) => item.name.toLowerCase().indexOf(this.text_price.toLowerCase()) !== -1);
		},

		pickClaimPrice(p) {
			console.log('pickClaimPrice', p.id);
			this.price_id = p.id;
			this.text_price = p.name;
			this.priceList = [];
		},

		faultClaim(fault) {
			this.claim.fault = fault;
			this.saveClaim(this.claim);
		},
	},

	created() {
		this.getClaim();
	},
};
</script>