<template>
    <div class="pb-4 w-full flex flex-wrap items-center">
        <h2 class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4">
            <span class="w-full text-lg leading-none text-teal-700">
                {{ this.PriceList?.length }}
            </span>
        </h2>
        <div class="flex-2_0 mr-15px min-w-[180px] my-4">
            <input type="text" class="h-9 w-full px-3 py-2 text-sm bg-slate-50" placeholder="Название" v-model="s.findme" @input="findPrice(s)" />
        </div>

        <select class="my-2 h-9 w-44 mr-4 bg-slate-50" @change="findPrice(s)" v-model="s.category">
            <template v-for="category in priceCategories" :key="category">
                <option v-if="s.category == category.id" selected :value="category.id">
                    {{ category.name }}
                </option>
                <option v-else :value="category.id">{{ category.name }}</option>
            </template>
        </select>
        <button v-if="s.clear" class="v-btn bg-gray-500 hover:bg-gray-400 mr-4" @click="Clear">
            <span class="v-btn_text">Сброс</span>
        </button>
        <template v-if="['Studio', 'Director', 'Finance'].includes(role)">
            <div class="flex">
                <button class="v-btn bg-teal-700 hover:bg-teal-600 transition-colors duration-150 mr-2 uppercase text-white font-latobold">
                    <router-link to="/price/categories">Категории</router-link>
                </button>
                <button class="v-btn bg-green-500 hover:bg-green-400 transition-colors duration-150" @click="addPrice">
                    <svg class="fill-white w-4 h-4 mr-3 rotate-45"><use xlink:href="@/assets/i.svg#ico-no"></use></svg>
                    <span class="v-btn_text">Создать</span>
                </button>
            </div>
        </template>
    </div>

    <div class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto">
        <div class="relative h-10 min-w-full">
            <div class="grid grid-cols-price-table items-center h-10 v-bbgray px-4 uppercase absolute min-w-full">
                <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]" name="price.iid">№</div>
                <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]" name="price_category.name">Категория</div>
                <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]" name="price.name">Услуга</div>

                <div v-if="profile.role != 'Master'" class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-right">
                    Стоимость
                </div>
                <template v-if="profile.role == 'Finance'">
                    <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-right">Себес-ть</div>
                    <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-right">Маржа</div>
                    <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-right">Марж-сть</div>
                </template>

                <div v-else class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-right" sort="time" k="s_time">
                    Время
                </div>
                <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-right">ЗП</div>
            </div>
        </div>

        <div id="list" v-if="PriceList?.length > 0">
            <template v-for="item in PriceList" :key="item">
                <div
                    :class="{'bg-teal-light odd:bg-white': item.del == 0, 'bg-red-400': item.del == 1}"
                    class="grid grid-cols-price-table v-bbgray p-4 items-center transition duration-300 hover:shadow-v1 cursor-pointer group max-2xl:grid-cols-all-mobile max-2xl:auto-rows-[50px]"
                    @click="showPrice(item)"
                >
                    <div class="cell id">{{ item.iid }}</div>
                    <div class="cell scl-hide">{{ item.category?.name }}</div>

                    <a 
                    :class="{'group-hover:text-green-500': item.del == 0, 'group-hover:text-black': item.del == 1}"
                    class="font-latobold text-base  transition-colors duration-150">{{ item.name }}</a>

                    <div v-if="profile.role != 'Master'" class="text-right font-latobold text-base">
                        {{ item.sum }}
                    </div>

                    <template v-if="profile.role == 'Finance'">
                        <div class="text-right font-latobold text-base">{{ item.cost }}</div>
                        <div class="text-right font-latobold text-base">
                            {{ item.sum - item.fix - item.cost }}
                        </div>
                        <div class="text-right font-latobold text-base">
                            {{ item.sum ? (((item.sum - item.fix - item.cost) * 100) / item.sum).toFixed(0) + "%" : null }}
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-right font-latobold text-base">{{ item.time }}</div>
                        <div class="text-right font-latobold text-base">{{ item.fix }}</div>
                    </template>
                </div>
            </template>
        </div>
        <div v-else class="py-2 flex justify-center">
            <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "PagePrice",

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "priceCategories", "PriceList"]),
    },

    methods: {
        ...mapActions(["getPrices", "addPrice", "setPrice", "findPrice", "setSettings", "clearSettings", "showPrice"]),

        Clear() {
            this.clearSettings();
            this.findPrice(this.s);
        },
    },

    mounted() {
        this.getPrices();
    },
};
</script>