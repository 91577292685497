import API from "../../services/api";
import router from "@/router/router";

export default {
    state: {
        orders: [],
        filteredOrders: {},
        countOrders: {},
        orderArhives: [],
        filteredOrderArhives: [],
        order: {},
        orderClients: [],
        orderCompanies: [],
        orderContacts: [],
        orderTs: [],
        explain: [],
        prices_order: [],
        oper: [],
        boxes: [],
        newcal: [],
        calendarik: [],
        qcheck: {},
        times: [
            "08:00:00",
            "08:15:00",
            "08:30:00",
            "08:45:00",
            "09:00:00",
            "09:15:00",
            "09:30:00",
            "09:45:00",
            "10:00:00",
            "10:15:00",
            "10:30:00",
            "10:45:00",
            "11:00:00",
            "11:15:00",
            "11:30:00",
            "11:45:00",
            "12:00:00",
            "12:15:00",
            "12:30:00",
            "12:45:00",
            "13:00:00",
            "13:15:00",
            "13:30:00",
            "13:45:00",
            "14:00:00",
            "14:15:00",
            "14:30:00",
            "14:45:00",
            "15:00:00",
            "15:15:00",
            "15:30:00",
            "15:45:00",
            "16:00:00",
            "16:15:00",
            "16:30:00",
            "16:45:00",
            "17:00:00",
            "17:15:00",
            "17:30:00",
            "17:45:00",
            "18:00:00",
            "18:15:00",
            "18:30:00",
            "18:45:00",
            "19:00:00",
            "19:15:00",
            "19:30:00",
            "19:45:00",
            "20:00:00",
            "20:15:00",
            "20:30:00",
            "20:45:00",
            "21:00:00",
            "21:15:00",
            "21:30:00",
            "21:45:00",
        ],
        orderPaginator: {
            page: 0,
            itemsInPage: 10
        },
        newGarland: {},
    },

    mutations: {
        setOrders: (state, orders) => {
            state.orders = orders;
            state.filteredOrders = orders;
            state.countOrders = orders;
        },
        filteredOrders: (state, orders) => {
            state.filteredOrders = orders;
        },
        setOrderArhives: (state, orders) => {
            state.orderArhives = orders;
            state.filteredOrderArhives = orders;
        },
        setOrder: (state, data) => {
            state.order = data;
        },
        setTimer: (state, timer) => {
            let order = state.order;
            order.timer = timer;
            state.order = order;
        },
        setExplain: (state, data) => {
            state.order.explain = data;
        },
        setOper: (state, data) => {
            state.order.oper = data;
        },
        setComments: (state, data) => {
            state.order.comments = data;
        },
        setOrderClients: (state, data) => {
            state.orderClients = data;
        },
        setOrderCompanies: (state, data) => {
            state.orderCompanies = data;
        },
        setOrderContacts: (state, data) => {
            state.orderContacts = data;
        },
        setOrderTs: (state, data) => {
            state.orderTs = data;
        },
        setBoxes: (state, data) => {
            state.boxes = data;
        },
        setNewcal: (state, data) => {
            console.log("Пришел календарь", data);
            state.newcal = data;
        },
        calendarik: (state, calendarik) => {
            state.calendarik = calendarik;
        },
        setQcheck: (state, qckeck) => {
            state.qckeck = qckeck;
        },
        setPrices: (state, prices_order) => {
            state.prices_order = prices_order;
        },
        setGarland: (state, newGarland) => {
            state.newGarland = newGarland;
        }
    },

    actions: {
        setGarland(ctx, garland) {
            console.log("setGarland", garland);
            ctx.commit("setGarland", garland);
            localStorage.setItem("garland", JSON.stringify(garland));
        },
        getNewGarland(ctx, data) {
            let order = localStorage.getItem("order");
            if (order) {
                order = JSON.parse(order);
                return API.GET("garlands/order", { id: data.id }).then((res) => {
                    ctx.dispatch("setGarland", res.data.garland);
                });
            } else {
                router.push("/order");
            }
        },
        setPrices(ctx, prices_order) {
            // console.log("setBid info", prices);
            ctx.commit("setPrices", prices_order);
            localStorage.setItem("prices_order", JSON.stringify(prices_order));
        },

        

        async getOrdersHosd(ctx) {
            const t0 = performance.now();

            ctx.dispatch("setLoader", true);
            ctx.dispatch("statusOrder", ctx.rootState.s);

            var res = await API.GET("order/orders_hosd");
            try{
                ctx.commit("setOrders", res.data.orders.sort((a, b)=>{
                    if((a.dates==null) || (b.dates==null))return 0;
                    if(a.dates[0]==null)return 1;
                    if(b.dates[0]==null) return -1;
                    if(a.dates[0]?.date > b.dates[0]?.date) return 1;
                    if(a.dates[0]?.date < b.dates[0]?.date) return -1;
                    if(a.dates[0]?.start > b.dates[0]?.start) return 1;
                    if(a.dates[0]?.start < b.dates[0]?.start) return -1;
                    return 0;
                }));
                }catch(e){
                    console.log('ORDER', res.data);
                }

                ctx.dispatch("statusOrder", ctx.rootState.s).then(() => {
                    console.log(performance.now() - t0, "ms");
                    ctx.dispatch("setLoader", false);
                });
        },

        async getOrders(ctx) {
            const t0 = performance.now();

            ctx.dispatch("setLoader", true);
            ctx.dispatch("statusOrder", ctx.rootState.s);

            var res = await API.GET("order/orders");
            try{
                ctx.commit("setOrders", res.data.orders.sort((a, b)=>{
                    if((a.dates==null) || (b.dates==null))return 0;
                    if(a.dates[0]==null)return 1;
                    if(b.dates[0]==null) return -1;
                    if(a.dates[0]?.date > b.dates[0]?.date) return 1;
                    if(a.dates[0]?.date < b.dates[0]?.date) return -1;
                    if(a.dates[0]?.start > b.dates[0]?.start) return 1;
                    if(a.dates[0]?.start < b.dates[0]?.start) return -1;
                    return 0;
                }));
                }catch(e){
                    console.log('ORDER', res.data);
                }

                ctx.dispatch("statusOrder", ctx.rootState.s).then(() => {
                    console.log(performance.now() - t0, "ms");
                    ctx.dispatch("setLoader", false);
                });
        },

        async getProlons(ctx) {
            const t0 = performance.now();

            ctx.dispatch("setLoader", true);
            ctx.dispatch("statusOrder", ctx.rootState.s);

            var res = await API.GET("order/prolon");
            try{
                ctx.commit("setOrders", res.data.orders.sort((a, b)=>{
                    if((a.dates==null) || (b.dates==null))return 0;
                    if(a.dates[0]==null)return 1;
                    if(b.dates[0]==null) return -1;
                    if(a.dates[0]?.date > b.dates[0]?.date) return 1;
                    if(a.dates[0]?.date < b.dates[0]?.date) return -1;
                    if(a.dates[0]?.start > b.dates[0]?.start) return 1;
                    if(a.dates[0]?.start < b.dates[0]?.start) return -1;
                    return 0;
                }));
                }catch(e){
                    console.log('ORDER', res.data);
                }

                ctx.dispatch("statusOrder", ctx.rootState.s).then(() => {
                    console.log(performance.now() - t0, "ms");
                    ctx.dispatch("setLoader", false);
                });
        },


        getOrderArhive(ctx) {
            const t0 = performance.now();

            ctx.dispatch("setLoader", true);
            ctx.commit("setOrders", []);

            API.GET("order/arhive", { findme: ctx.rootState.s.findme }).then((res) => {
                ctx.commit("setOrders", res.data.orders);
                ctx.dispatch("statusOrder", ctx.rootState.s).then(() => {
                    console.log(performance.now() - t0, "ms");
                    ctx.dispatch("setLoader", false);
                });
            });
        },
        getOrderPrices(ctx) {
            let order = localStorage.getItem("order");
            if (order) {
                order = JSON.parse(order);
                return API.GET("order/list-price", { id: order.id }).then((res) => {
                    res.data.prices_order ? ctx.dispatch("setPrices", res.data.prices_order) : null;
                });
            }
        },

        fillOrders(ctx) {
            ctx.commit("filteredOrders", ctx.state.orders);
        },

        loadOrders(ctx) {
            let orders = [];
            if (orders && orders?.length > 0) {
                ctx.commit("setOrders", orders);
            }
        },

        async getOrder(ctx) {
            let order = localStorage.getItem("order");
            if (order) {
                order = JSON.parse(order);
                ctx.commit("setOrder", order);
                if (ctx.state.order.id) {

                    var resOne = await API.GET("order/one", { id: ctx.state.order.id });
                    ctx.dispatch("setOrder", resOne.data.order);
                  
                    var resMore = await API.GET("order/more", { id: ctx.state.order.id });
                    ctx.commit("setExplain", resMore.data.explain);
                    ctx.commit("setOper", resMore.data.oper);
                    console.log("Приход", ctx.state.order.id);

                    var resCal = await API.GET('calendar/for-master', { id: ctx.state.order.master_id });
                    ctx.commit("setNewcal", resCal.data.newcal);
                  
                    if (resOne.data.order.client_id) {
                        ctx.dispatch("getOrderContactsCars", resOne.data.order.client_id);
                    }
                }
            }
        },

        

        getOneOrder(ctx, id) {
            API.GET("order/one", { id: id }).then((res) => {
                ctx.dispatch("setOrder", res.data.order);
                API.GET("order/more", { id: ctx.state.order.id }).then((res) => {
                    ctx.commit("setExplain", res.data.explain);
                    ctx.commit("setOper", res.data.oper);
                    ctx.commit("setNewcal", res.data.newcal);
                });
            });
        },

        findOrder(ctx, s) {
            if (!ctx.state.orders) {
                return null;
            }
            let list = ctx.state.orders;
            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let iid = null;
                    let num = null;
                    let client = null;
                    let code = null;
                    let adressOrder = null;
                    iid = item.iid.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.num) {
                        num = item.num.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.cname) {
                        client = item.cname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.code) {
                        code = item.code.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.address) {
                        adressOrder = item.address.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (iid || num || client || code || adressOrder) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.manager != "" && s.manager != undefined) {
                list = list.filter((item) => String(item.manager_id) == String(s.manager));
            }

            if (s.creator != "" && s.creator != undefined) {
                list = list.filter((item) => String(item.uid) == String(s.creator));
            }

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => String(item.master_id) == String(s.master));
            }

            if (s.pay != "" && s.pay != undefined) {
                list = list.filter((item) => s.pay.includes(parseInt(item.pay)));
            }

            if (s.pay_status != "" && s.pay_status != undefined) {
                list = list.filter((item) => String(item.pay_status) == String(s.pay_status));
            }

            if (s.category != "" && s.category != undefined) {
                list = list.filter((item) => String(item.category) == String(s.category));
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.dates && item.dates[0]?.date >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.dates && item.dates[0]?.date <= s.end);
            }
            
            if((s.createStart != "" && s.createStart != null)){
                list = list.filter((item)=>item.create_at.slice(0, 10) >= s.createStart);
            }

            if((s.createEnd != "" && s.createEnd != null)){
                list = list.filter((item)=>item.create_at.slice(0, 10) <= s.createEnd);
            }

            if (s.order_in_agregator){
                list = list.filter((item) => item.in_agregator || (item.status == 20));
            }


            if(s.delay != ""){
                list = list.filter((item)=>{
                    if(s.delay=="0")
                        return (item.delay == null ) || (item.delay == 0);
                    return `${item.delay}` == s.delay;
                });
            }

            if(s.box != '' && s.box != null){
                list = list.filter(item=>{
                    switch(s.box){
                        case 'box': return item.box != null;
                        case 'ex': return (item.box == null) && (item.address != null);
                        case 'not': return (item.box == null) && (item.address == null);
                        default: return false;
                    }
                });
            }

            if (list.length != ctx.state.orders?.length) {
                s.clear = true;
            }

            ctx.state.filteredOrders = list;
            ctx.state.countOrders = list;
            ctx.dispatch("setSettings", s);
        },

        

        statusOrder(ctx, s) {
            ctx.dispatch("findOrder", s);
            let list = ctx.state.filteredOrders;

            if (s.claim != "" && s.claim != undefined) {
                list = list.filter((item) => item.r_id > 0);
            }
            
            if (s.doc != "" && s.doc != undefined) {
                list = list.filter((item) => parseInt(item.status) == 1 && parseInt(item.act) > 0);
            }

            if (s.trans != "" && s.trans != undefined) {
                list = list.filter((item) => [3, 4, 7].includes(parseInt(item.act)) && [1, 3, 4, 5, 10].includes(parseInt(item.status)));
            }

            if (s.status != "" && s.status != undefined) {
                if (s.status == 10) {
                    list = list.filter((item) => parseInt(item.status) == 10 && ![1, 2, 3, 4, 7].includes(parseInt(item.act)));
                } else {
                    list = list.filter((item) => parseInt(item.status) == parseInt(s.status));
                }
            }

            if (list.length != ctx.state.orders?.length) {
                s.clear = true;
            }

            ctx.state.filteredOrders = list;
        },

        filterPayNoAnket(ctx) {
            let noAnketList = ctx.state.filteredOrders.filter(
                (elem) => [
                    elem.pay_status == 6,
                    (parseInt(elem.act)===1) || (elem.act==null),
                    [1, 2, 3, 10].includes(parseInt(elem.status)),
                    elem.prices.some(({price})=>price.act),
                ].every(Boolean)
            );    
            
            ctx.state.filteredOrders = noAnketList;
        },

        // оплачено, ждут анкету
        filterHosdWait(ctx) {        
            let HosdWaitList = ctx.state.filteredOrders.filter(
                (elem) => [
                    elem.ipay_status == 6,
                    elem.act !== 8,
                    elem.act != null
                ].every(Boolean)
            );    
            ctx.state.filteredOrders = HosdWaitList;
        },

        // заказы не оплачены ждут анкету
        filterHosdWait1(ctx) {
            let HosdWaitList1 = ctx.state.filteredOrders.filter(
                (elem) => [
                    elem.ipay_status == 1,
                    elem.act != 8,
                    elem.act != null
                ].every(Boolean)
            );    
            
            ctx.state.filteredOrders = HosdWaitList1;
        },

        // заказ не оплачен, без анкеты
        filterHosdWaitNoAnket(ctx) {
            let HosdnoAnketList = ctx.state.filteredOrders.filter(
                (elem) => [
                    elem.ipay_status == 1,
                    elem.act == null,
                ].every(Boolean)
            );    
            
            ctx.state.filteredOrders = HosdnoAnketList;
        },

              // оплачено, ждут анкету, по дедлайну проеб
        filterHosdWaitProeb(ctx) {
            let now = new Date();
            let HosdProebList = ctx.state.filteredOrders.filter(
                (elem) => [
                    elem.ipay_status == 6,
                    elem.act != 8,
                    elem.act != null,
                    elem.acreate_at && (new Date(new Date(elem.acreate_at.replace(' ', 'T')).getTime() + (1 * 24 * 60 * 60 * 1000)) < now)
                ].every(Boolean)
            );    
            
            ctx.state.filteredOrders = HosdProebList;
        },

        findOrderArhive(ctx, s) {
            let list = ctx.state.orderArhives;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let iid = null;
                    let num = null;
                    let tsnum = null;
                    let client = null;
                    iid = item.iid.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.num) {
                        num = item.num.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.tsnum) {
                        tsnum = item.tsnum.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.cname) {
                        client = item.cname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (iid || num || tsnum || client) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.manager != "" && s.manager != undefined) {
                list = list.filter((item) => String(item.manager_id) == String(s.manager));
            }

            if (s.master != "" && s.master != undefined) {
                list = list.filter((item) => String(item.master_id) == String(s.master));
            }

            if (s.pay != "" && s.pay != undefined) {
                list = list.filter((item) => s.pay.includes(parseInt(item.pay)));
            }

            if (s.pay_status != "" && s.pay_status != undefined) {
                list = list.filter((item) => String(item.pay_status) == String(s.pay_status));
            }

            if (s.category != "" && s.category != undefined) {
                list = list.filter((item) => String(item.category) == String(s.category));
            }

            if (s.start != "" && s.start != undefined) {
                list = list.filter((item) => item.dates && item.dates[0]?.date >= s.start);
            }

            if (s.end != "" && s.end != undefined) {
                list = list.filter((item) => item.dates && item.dates[0]?.date <= s.end);
            }

            if (s.claim != "" && s.claim != undefined) {
                list = list.filter((item) => item.r_id > 0);
            }

            if (s.doc != "" && s.doc != undefined) {
                list = list.filter((item) => parseInt(item.status) == 1 && parseInt(item.act) > 1);
            }

            if (s.status != "" && s.status != undefined) {
                list = list.filter((item) => String(item.status) == String(s.status));
            }

            if (s.order_in_agregator){
                list = list.filter((item) => item.in_agregator || (item.status == 20));
            }

            if (list.length != ctx.state.orderArhives.length) {
                s.clear = true;
            }

            ctx.state.filteredOrderArhives = list;
            ctx.dispatch("setSettings", s);
        },

        setOrder(ctx, order) {
            ctx.commit("setOrder", order);
            localStorage.setItem("order", JSON.stringify(order));
            if (order && order.kk) {
                ctx.commit("setQcheck", order.kk);
            }
        },

        

        addOrder(ctx, formData = null) {
            // console.log("addOrder");
            API.POST("order/add-order", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                router.push("/order/edit");
            });
        },

        showOrder(ctx, order) {
            ctx.dispatch("setOrder", order);
            if (ctx.rootState.profile.role == "Client") {
                router.push("/order/view");
                // window.open("/order/edit", '_blank');
            } else {
                router.push("/order/edit");
                // window.open("/order/edit", '_blank');
            }
        },

        goToOrderNow(ctx, order_id) {
            return API.GET("order/one", { id: order_id }).then((res) => {
                ctx.dispatch("setOrder", res.data.order);
                router.push("/order/edit");
            });
        },

        sendOrderLeadMakeByMaster(ctx, order) {
            let formData = new FormData();
            formData.append("order_id", order.order_id);
            formData.append("client_id", order.client_id);
            formData.append("comment", order.comment);

            API.POST("lead/master-lead", formData).then((res) => {
                res.data.lead ? ctx.dispatch("setLead", res.data.lead) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                router.push("/lead/edit", '_blank');
            });
        },

        CreateOrderFromInvoice(ctx, invoice) {
            let formData = new FormData();
            formData.append("id", invoice.id);
            API.POST("invoice/create-order", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                window.open("/order/edit", '_blank');
            });
        },

        showOrderReport(ctx, order) {
            ctx.dispatch("setOrder", order);
            if (ctx.rootState.profile.role == "Client") {
                // router.push("/order/view");
                window.open("/order/edit", '_blank');
            } else {
                // router.push("/order/edit");
                window.open("/order/edit", '_blank');
            }
        },

        editOrderId(ctx, id) {
            ctx.commit("setOrder", null);
            localStorage.removeItem("order");
            ctx.rootState.s.popup = null;
            ctx.dispatch("getOneOrder", id).then(() => {
                router.push("/order/edit");
            });
        },

        setBox(ctx, item) {
            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("box", item.id);
            API.POST("order/change-address", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setZone(ctx, zone) {
            ctx.state.order.zone_id = zone;
            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("zone_id", zone);
            API.POST("order/set-zone", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },
        setAddress(ctx, address) {
            ctx.state.order.address = address;
            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("address", address);
            API.POST("order/change-address", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delAddress(ctx) {
            ctx.state.order.address = '';
            ctx.state.order.box = null;
            ctx.state.order.zone_id = 0;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            API.POST("order/del-address", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        // КЛИЕНТ
        findOrderClient(ctx, find) {
            console.log("findClient");
            API.GET("order/client-list", { id: ctx.state.order.id, find: find }).then((res) => {
                ctx.commit("setOrderClients", res.data);
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setOrderClient(ctx, item) {
            ctx.state.order.client_id = item.id;
            ctx.state.order.client = { loader: 1 };
            ctx.dispatch("setOrder", ctx.state.order);
            ctx.commit("setOrderClients", null);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("client_id", item.id);
            API.POST("order/change-client", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                ctx.commit("setOrderContacts", res.data.contacts);
                ctx.commit("setOrderTs", res.data.ts);
            });
        },

        delOrderClient(ctx) {
            ctx.state.order.client_id = null;
            ctx.state.order.contact_id = null;
            ctx.state.order.ts_id = null;
            ctx.commit("setOrderContacts", null);
            ctx.commit("setOrderTs", null);
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("client_id", 0);
            API.POST("order/change-client", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        // КОМПАНИЯ
        setOrderCompany(ctx, item) {
            ctx.state.order.company_id = item.id;
            ctx.state.order.company = { loader: 1 };
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("client_id", ctx.state.order.client_id);
            formData.append("company_id", item.id);
            API.POST("order/change-company", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
            this.find = "";
        },

        delOrderCompany(ctx) {
            ctx.state.order.company_id = null;
            ctx.state.order.company = null;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("company_id", 0);
            API.POST("order/change-company", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        // КОНТАКТ
        getOrderContactsCars(ctx, client_id) {
            let formData = new FormData();
            formData.append("client_id", client_id);
            API.POST("order/get-contacts", formData).then((res) => {
                ctx.commit("setOrderCompanies", res.data.companies);
                ctx.commit("setOrderContacts", res.data.contacts);
                ctx.commit("setOrderTs", res.data.ts);
            });
        },

        clearOrderContactsCars(ctx) {
            ctx.commit("setOrderContacts", null);
            ctx.commit("setOrderTs", null);
        },

        setOrderContact(ctx, item) {
            ctx.state.order.contact_id = item.id;
            ctx.state.order.contact = { loader: 1 };
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("client_id", ctx.state.order.client_id);
            formData.append("contact_id", item.id);
            API.POST("order/change-contact", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
            this.find = "";
        },

        delOrderContact(ctx) {
            ctx.state.order.contact_id = null;
            ctx.state.order.contact = null;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("contact_id", 0);
            API.POST("order/change-contact", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        // ТС
        setOrderTs(ctx, item) {
            ctx.state.order.ts_id = item.id;
            ctx.state.order.ts = { loader: 1 };
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("ts_id", item.id);
            API.POST("order/change-ts", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delOrderTs(ctx) {
            ctx.state.order.ts_id = null;
            ctx.state.order.ts = null;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("ts_id", null);
            API.POST("order/change-ts", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        // КАЛЕНДАРЬ
        setCalendarik(ctx, calendarik) {
            ctx.commit("calendarik", calendarik);
        },

        // сохраним при закрытии попап календаря
        saveDate(ctx, data) {
            console.log("saveDate: ", data);
            if (data.date && data.slots) {
                // console.log("slots: ", JSON.stringify(data.slots));
                let formData = new FormData();
                formData.append("id", ctx.state.order.id);
                if (data.master) {
                    formData.append("master_id", data.master);
                }
                formData.append("date", data.date);
                formData.append("slots", data.slots);
                API.POST("order/set-date", formData).then((res) => {
                    res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                    res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                    ctx.commit("setNewcal", res.data.newcal);
                });
            }
        },

        setOrderCRMNumber(ctx, order) {
            let formData = new FormData();
            formData.append("id", order.id);
            formData.append("number_crm", order.number_crm);
            API.POST("order/change-number-crm", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delDate(ctx, d) {
            // console.log("delDate: ", d);
            ctx.state.order.dates = ctx.state.order.dates.filter((item) => item != d);
            // ctx.state.order.status = 2;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("date_id", d.id);
            formData.append("date", d.date);
            formData.append("start", d.start);
            formData.append("end", d.end);
            API.POST("/order/del-date", formData).then((res) => {
                ctx.commit("setNewcal", res.data.newcal);
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        // getOrderHistory(ctx) {
        //     API.GET("order/histories", { id: ctx.state.order.id }).then((res) => {
        //         ctx.commit("setOrderHistory", res.data.histories);
        //     });
        // },

        // МАСТЕР
        async setMaster(ctx, item) {

            ctx.state.order.master = {
                id: item.id,
                uid: item.uid,
                secondname: item.secondname,
                name: item.name,
                picture: item.picture,
            };

            // if (ctx.state.order.dates?.length > 0 && ctx.state.order.pay_status == 6) {
            //     ctx.state.order.status = 3;
            // }
            
            ctx.commit("setOrder", ctx.state.order);
            localStorage.setItem("order", JSON.stringify(ctx.state.order));

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("master_id", item.uid);
            formData.append("status", 3);
            var res = await API.POST("order/change-master", formData)
            res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
            res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            //console.log("Приход мастера", ctx.state.order.id);
            res = await API.GET('calendar/for-master', { id: ctx.state.order.master.uid });
            ctx.commit("setNewcal", res.data.newcal);
        },

        async delMaster(ctx) {
            ctx.state.order.master_id = null;
            ctx.state.order.master = null;
            // ctx.state.order.status = 2;

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("master_id", 0);
            formData.append("status", 10);
            var res = await API.POST("order/change-master", formData);
            res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
            res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;          
        },

        // УСЛУГА
        setOrderPrice(ctx, item) {
            let order = ctx.state.order;
            order.prices.push({ loader: 1 });
            ctx.dispatch("setOrder", order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("price_id", item.id);
            API.POST("order/add-price", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        newOrderPrice(ctx, item) {
            console.log("newOrderPrice", item);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("order_price_id", item.id);
            formData.append("price_id", item.price_id);
            formData.append("sum", item.sum);
            API.POST("order/change-sum", formData).then((res) => {
                ctx.state.order.prices = res.data.prices;
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        delOrderPrice(ctx, p) {
            console.log("delPrice: ", p);
            ctx.state.order.prices = ctx.state.order.prices.filter((item) => item != p);
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("order_price_id", p.id);
            API.POST("order/del-price", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setOrderPay(ctx, pay) {
            ctx.state.order.pay = pay;
            if (parseInt(pay) == 1) {
                ctx.state.order.pay_status = 6;
            } else {
                ctx.state.order.pay_status = 1;
            }

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("pay", pay);
            API.POST("order/set-pay", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setOrderCode(ctx, order) {
            let formData = new FormData();
            formData.append("id", order.id);
            formData.append("code", order.code);
            API.POST("order/change-code", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setOrderCodebill(ctx, order) {
            let formData = new FormData();
            formData.append("id", order.id);
            formData.append("codebill", order.codebill);
            API.POST("order/change-codebill", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },
        setOrderField(ctx, {order, field, value}){
            let formData = new FormData();
            formData.append("id", order.id);
            formData.append("field", field);
            formData.append("value", value);
            API.POST("order/set-field", formData).then((res) => {
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        // статусы
        NewStatusOrder(ctx) {
            ctx.state.order.status = 1;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("status", 1);
            API.POST("order/new-status", formData).then((res) => {
                res.data.timer ? ctx.commit("setTimer", res.data.timer) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        StartOrder(ctx) {
            ctx.state.order.status = 4;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("status", 4);
            API.POST("order/start", formData).then((res) => {
                console.log("StartOrder", res.data.order);
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                ctx.commit("setExplain", res.data.explain);
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        StopOrder(ctx) {
            ctx.state.order.status = 3;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("status", 3);
            API.POST("order/stop", formData).then((res) => {
                res.data.timer ? ctx.commit("setTimer", res.data.timer) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        sendSms(ctx) {
            console.log("sendSms");
            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            API.POST("order/send-sms", formData);
        },

        checkSms(ctx, code) {
            console.log("checkSms: ", code);
            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("smscode", code);
            API.POST("order/finish", formData).then((res) => {
                ctx.commit("setResult", res.data.result);
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                ctx.commit("setExplain", res.data.explain);
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        AcceptOrder(ctx) {
            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("status", 6);
            API.POST("order/accept", formData).then((res) => {
                res.data.timer ? ctx.commit("setTimer", res.data.timer) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                if(res.data.alert.type === 'success'){
                    ctx.state.order.status = 6;
                    ctx.dispatch("setOrder", ctx.state.order);
                }
            });
        },

        DenyOrder(ctx) {
            ctx.state.order.status = 7;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("status", 7);
            API.POST("order/deny", formData).then((res) => {
                res.data.timer ? ctx.commit("setTimer", res.data.timer) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },
        ReassignOrder(ctx){
            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            API.POST("order/reassign", formData).then((res) => {
                if(res?.data?.alert?.type==='success'){
                    ctx.state.order.status = 3;
                    ctx.dispatch("setOrder", res.data.order);

                }
            });
        },

        // чтобы завершить - пройди КК и сохрани анкету (saveQcheck)
        // DoneOrder(ctx) {
        //     ctx.state.order.status = 8;
        //     ctx.dispatch("setOrder", ctx.state.order);

        //     let formData = new FormData();
        //     formData.append("id", ctx.state.order.id);
        //     formData.append("status", 8);
        //     API.POST("order/done", formData).then((res) => {
        //         ctx.state.order.timer = res.data.timer;
        //         res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
        //     });
        // },

        DelayOrder(ctx, delay) {
            ctx.state.order.status = 10;
            ctx.state.order.delay = delay;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("delay", delay);
            API.POST("order/delay-type", formData).then((res) => {
                res.data.timer ? ctx.commit("setTimer", res.data.timer) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        cancelOrder(ctx, data) {
            ctx.state.order.status = 11;
            ctx.state.order.timer = null;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("cancel_id", data.cancel_id);
            formData.append("comment", data.comment);
            API.POST("order/cancel", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        AnketaOrder(ctx) {
            ctx.state.order.act = 1;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            API.POST("order/anketa-status", formData).then((res) => {
                res.data.timer ? ctx.commit("setTimer", res.data.timer) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        checkAnketaOrder(ctx) {
            ctx.state.order.act = 2;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            API.POST("order/check-anketa-status", formData).then((res) => {
                res.data.timer ? ctx.commit("setTimer", res.data.timer) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        setStatus(ctx, status) {
            if ([6, 8].includes(status)) {
                ctx.state.order.timer = null;
            }
            ctx.state.order.status = status;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("status", status);
            API.POST("order/set-status", formData).then((res) => {
                res.data.timer ? ctx.commit("setTimer", res.data.timer) : null;
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        pushTaskinOrder(ctx, task) {
            ctx.state.order.tasks.push(task);
            ctx.dispatch("setOrder", ctx.state.order);
        },

        saveOper(ctx, oper) {
            console.log("saveOper");
            ctx.state.order.oper = oper;
            ctx.dispatch("setOrder", ctx.state.order);

            let formData = new FormData();
            Object.keys(oper).forEach(function (key) {
                formData.append(key, this[key]);
            }, oper);
            API.POST("order/save-oper", formData);
        },

        sendExplain(ctx, data) {
            ctx.state.order.explain.status = 2;
            ctx.state.order.explain.info = data.text;
            ctx.dispatch("setOrder", ctx.state.order);
            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            formData.append("violate_id", data.id);
            formData.append("explain", data.text);
            API.POST("order/add-explain", formData);
        },

        delOrder(ctx) {
            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            API.POST("order/del-order", formData);
            router.push("/order");
        },

        claimCreate(ctx) {
            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            API.POST("claim/create", formData).then((res) => {
                ctx.dispatch("setClaim", res.data.claim);
                router.push("/claim/view");
            });
        },

        getOrderView(ctx) {
            console.log("getOrderView");
            let order = localStorage.getItem("order");
            if (order) {
                order = JSON.parse(order);
                ctx.commit("setOrder", order);
                API.GET("order/client-one", { id: ctx.state.order.id }).then((res) => {
                    res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                    res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                    ctx.commit("setOrderCompanies", res.data.companies);
                    ctx.commit("setOrderContacts", res.data.contacts);
                    ctx.commit("setOrderTs", res.data.ts);
                });
            }
        },

        getQckeck(ctx) {
            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            API.POST("order/qcheck", formData).then((res) => {
                ctx.commit("setQcheck", res.data.anketa);
            });
        },

        saveQcheck(ctx, qcheck) {
            let formData = new FormData();
            formData.append("client_id", ctx.state.order.client_id);
            formData.append("studio_id", ctx.state.order.studio_id);
            Object.keys(qcheck).forEach(function (key) {
                formData.append(key, this[key]);
            }, qcheck);
            API.POST("order/save-qcheck", formData).then((res) => {
                console.log("data", res.data);
                ctx.state.order.kk = qcheck;
                ctx.state.order.kk.status = 8;
                ctx.state.order.qcheck = res.data.qcheck;
                ctx.state.order.mcheck = res.data.mcheck;
                ctx.state.order.status = 8;
                ctx.dispatch("setOrder", ctx.state.order);
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },

        createActivation(ctx) {
            let formData = new FormData();
            formData.append("id", ctx.state.order.id);
            API.POST("order/create-activation", formData).then((res) => {
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
                ctx.rootState.s.popup = "doc";
                ctx.dispatch("setSettings", ctx.rootState.s);
            });
        },

        copyOrder(ctx, order_id) {
            let formData = new FormData();
            formData.append("id", order_id);
            API.POST("order/copy", formData).then((res) => {
                console.log("copyOrder", res.data.order);
                res.data.order ? ctx.dispatch("setOrder", res.data.order) : null;
                res.data.alert ? ctx.commit("setAlert", res.data.alert) : null;
            });
        },
        async GroupOrdersToAgregator(ctx, orders){
            console.log("на отправку", orders);
            let formData = new FormData();
            orders.forEach((id, pos)=>
                formData.append(`id[${pos}]`, id)
            )
            var res = API.POST('order/group-to-agregator', formData);
            return res?.data?.alert?.type == 'success';
        },
    },
    
    getters: {
        OrderList(state) {
            return state.filteredOrders;
        },
        ArchOrderList(state) {
            // let listSortedOrder = 
            // ? 1 : -1);
            return state.filteredOrders.sort(function(a, b) {
                return (b.dates[0]?.date<a.dates[0]?.date) - (a.dates[0]?.date<b.dates[0]?.date) || (b.dates[0]?.start<a.dates[0]?.start) - (a.dates[0]?.start<b.dates[0]?.start);
            });
        },

        OrderArhives(state) {
            return state.filteredOrderArhives;
        },
        OrderCounter(state) {
            let count = [];
            let vio = [];
            if (state.countOrders?.length > 0) {
                count[1] = 0;
                count[3] = 0;
                count[4] = 0;
                count[5] = 0;
                count[6] = 0;
                count[7] = 0;
                count[8] = 0;
                count["claim"] = 0;
                count["doc"] = 0;
                count['noank'] = 0;
                count["trans"] = 0;
                count[10] = 0;
                count[11] = 0;
                count["pay"] = 0;
                count[19] = 0;
                count.agregator = 0;
                vio["doc"] = 0;

                count[20] = 0;
                count[21] = 0;
                count[22] = 0;
                count[23] = 0;
                count[24] = 0;

                let checkProeb;
                let now = new Date();
                state.countOrders?.forEach((elem) => {
                    // просто все заказы
                    count[20]++;              

                    // оплачено, ждут анкету
                    if (elem.ipay_status == 6 && elem.act != 8 && elem.act != null) {
                        count[21]++;
                    }                    
                    // заказы не оплачены ждут анкету
                    if (elem.ipay_status == 1 && elem.act != 8 && elem.act != null) {
                        count[22]++;
                    }
                    // заказ не оплачен, без анкеты
                    if (elem.ipay_status == 1 && elem.act == null) {
                        count[23]++;
                    }
                    // оплачено, ждут анкету, по дедлайну проеб
                    if (elem.acreate_at != null) {                      
                        let acreate_at_date = new Date(elem.acreate_at.replace(' ', 'T'));
                        checkProeb = new Date(acreate_at_date.getTime() + (1 * 24 * 60 * 60 * 1000));  

                        if (elem.ipay_status == 6 && elem.act != 8 && elem.act != null && (checkProeb < now)) {
                            count[24]++;
                        }   
                    }

                    if (elem.status == 1) {
                        count[1]++;
                    }
                    if (elem.status == 3) {
                        count[3]++;
                    }
                    if (elem.status == 4) {
                        count[4]++;
                    }
                    if (elem.status == 5) {
                        count[5]++;
                    }
                    if (elem.status == 6) {
                        count[6]++;
                    }
                    if (elem.status == 7) {
                        count[7]++;
                    }
                    if (elem.status == 8) {
                        count[8]++;
                    }
                    if (elem.status == 10 && ![1, 2, 3, 4, 7].includes(parseInt(elem.act))) {
                        count[10]++;
                    }
                    if (elem.status == 11) {
                        count[11]++;
                    }
                    if (elem.pay_status == 1) {
                        count["pay"]++;
                    }
                    if (elem.r_id > 0) {
                        count["claim"]++;
                    }
                    if (elem.status == 1 && elem.act > 0) {
                        count["doc"]++;
                    }
                    if ([
                        elem.pay_status == 6,
                        (parseInt(elem.act)===1) || (elem.act==null),
                        [1, 2, 3, 10].includes(parseInt(elem.status)),
                        elem.prices.some(({price})=>price.act),
                    ].every(Boolean)) {
                        count["noank"]++;
                    }
                    if ([3, 4, 7].includes(parseInt(elem.act)) && [1, 3, 4, 5, 10].includes(parseInt(elem.status))) {
                        count["trans"]++;
                    }
                    if (elem.violate == 88) {
                        vio["doc"]++;
                    }
                    if(elem.in_agregator || (elem.status == 20)){
                        count.agregator++;
                    }  
                    
                    
                });                
            }
            return { count: count, vio: vio };
        },

        order(state) {
            return state.order;
        },

        oper(state) {
            return state.order.oper;
        },

        OrderHistory(state) {
            return state.order.histories;
        },

        OrderCal(state) {
            return state.newcal.cal;
        },

        srtCal(state) {
            return state.newcal.s;
        },

        endCal(state) {
            return state.newcal.e;
        },

        calendarik(state) {
            return state.calendarik;
        },

        times(state) {
            return state.times;
        },

        selectOrderClients(state) {
            return state.orderClients;
        },

        selectOrderCompanies(state) {
            return state.orderCompanies;
        },

        selectOrderContacts(state) {
            return state.orderContacts;
        },

        selectOrderTs(state) {
            return state.orderTs;
        },

        qcheck(state) {
            return state.qckeck;
        },
        prices_order(state) {
            return state.prices_order;
        },
        orderPaginator(state){
            return state.orderPaginator;
        },
        newGarland(state){
            return state.newGarland;
        },
        
    },
};
