<template>
  <div
    v-if="s.popup == 'changeMan'"
    id="popnew"
    class="w-1/2 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <h2 class="text-center text-2xl mt-2">Выберите нового менеджера</h2>
    <p class="text-center">он будет назначен выбранным клиентам</p>
    <p class="text-center mb-2">выбрано клиентов: {{ checkedIds.length }}</p>
    <div
      v-for="man in selectManagers"
      :key="man"
      class="flex px-4 py-2 v-bbgray items-center transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white"
      @click="
        setGroupManager({ clients: checkedIds, manager_id: man.uid }),
          (s.popup = false)
      "
    >
      <div class="w-11 h-11 rounded-full mr-3 overflow-hidden">
        <IcoPic :pic="man.picture" :name="man.name" />
      </div>
      <div class="w-full font-latobold">
        {{ man.name }} {{ man.secondname }}
      </div>
    </div>
  </div>
  <div
    v-if="s.popup"
    @click="closePopup()"
    class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
    id="overlay"
  ></div>

  <div
    id="count"
    class="mb-9 mt-4 grid grid-cols-order__top place-content-center gap-x-14px gap-y-24px"
  >
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus('zero')"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="ClientCounter"
        >
          {{ ClientCounter["count"]["zero"] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-20 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >0</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(1)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="ClientCounter"
        >
          {{ ClientCounter["count"][1] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-20 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >1</span
      >
      <span class="text-sm block lowercase mt-4"
        >{{ ClientCounter["realts"][1] }} из
        {{ ClientCounter["count"][1] }}</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(10)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="ClientCounter"
        >
          {{ ClientCounter["count"][10] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >2-10</span
      >
      <span class="text-sm block lowercase mt-4"
        >{{ ClientCounter["realts"][10] }} из
        {{ ClientCounter["numts"][10] }}</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(30)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="ClientCounter"
        >
          {{ ClientCounter["count"][30] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-2 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >11-30</span
      >
      <span class="text-sm block lowercase mt-4"
        >{{ ClientCounter["realts"][30] }} из
        {{ ClientCounter["numts"][30] }}</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(50)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="ClientCounter"
        >
          {{ ClientCounter["count"][50] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-5 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >31-50</span
      >
      <span class="text-sm block lowercase mt-4"
        >{{ ClientCounter["realts"][50] }} из
        {{ ClientCounter["numts"][50] }}</span
      >
    </div>
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(99)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="ClientCounter"
        >
          {{ ClientCounter["count"][99] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-6 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >>50</span
      >
      <span class="text-sm block lowercase mt-4"
        >{{ ClientCounter["realts"][99] }} из
        {{ ClientCounter["numts"][99] }}</span
      >
    </div>
  </div>
  <div class="pb-4 w-full flex flex-wrap items-center">
    <input
      class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[8.5rem] w-[8.5rem] mr-4"
      type="date"
      v-model="s.active_start"
      @change="setSettings(s)"
    />
    <input
      class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[8.5rem] w-[8.5rem] mr-4"
      type="date"
      v-model="s.active_end"
      @change="setSettings(s)"
    />
    <button
      :disabled="!s.active_end && !s.active_start"
      class="v-btn bg-gray-500 hover:bg-gray-400 mr-4 v-btn_text"
      @click="LoadClients(s)"
    >
      Показать
    </button>
  </div>
  <div class="pb-4 w-full flex flex-wrap items-center">
    <h2
      class="mr-15px min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4"
    >
      <span class="w-full text-lg leading-none text-teal-700 block">{{
        this.ClientList?.length
      }}</span>
      {{ ClientCounter["allrealts"] }} из {{ ClientCounter["allnumts"] }}
    </h2>

    <div class="flex-2_0 mr-15px min-w-[180px] my-4">
      <input
        type="text"
        class="h-9 w-full px-3 py-2 text-sm bg-slate-50"
        placeholder="Название"
        v-model="s.findme"
        @input="findClient(s)"
      />
    </div>

    <input
      class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[8.5rem] w-[8.5rem] mr-4"
      type="date"
      v-model="s.start"
      @change="findClient(s)"
    />
    <input
      class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[8.5rem] w-[8.5rem] mr-4"
      type="date"
      v-model="s.end"
      @change="findClient(s)"
    />

    <template v-if="['Manager', 'Logist', 'Studio', 'Check'].includes(role)">
      <select
        class="my-2 h-9 w-44 mr-4 bg-slate-50"
        @change="findClient(s)"
        v-model="s.manager"
      >
        <option value="0" disabled="disabled">Менеджер</option>
        <option value="none">Без менеджера</option>
        <template v-for="m in selectManagers" :key="m">
          <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
        </template>
      </select>
    </template>

    <button class="v-btn bg-gray-500 hover:bg-gray-400 mr-4">
      <span class="v-btn_text" @click="Clear">Сброс</span>
    </button>
    <template
      v-if="['Studio', 'Check'].includes(role) || parseInt(profile.uid) == 20"
    >
      <div class="flex">
        <a
          class="v-btn bg-green-500 hover:bg-green-400 mr-4 flex items-center"
          @click="addThisClient()"
        >
          <svg class="fill-white w-16px h-16px mr-3 rotate-45">
            <use xlink:href="@/assets/i.svg#ico-no"></use>
          </svg>
          <span class="v-btn_text">Создать</span>
        </a>
        <a
          class="v-btn bg-green-500 hover:bg-green-400 flex items-center"
          @click="s.popup = 'changeMan'"
        >
          <svg class="fill-white w-16px h-16px">
            <use xlink:href="@/assets/i.svg#ico-edit"></use>
          </svg>
        </a>
      </div>
    </template>
  </div>
  <select v-model="clientPaginator.itemsInPage" style="padding: 7px">
    <option value="10">10</option>
    <option value="20">20</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <Paginator :count="pageCount" v-model="clientPaginator.page" />

  <div
    v-if="PaginatedClientList?.length > 0 && !s.loader"
    style="position: relative"
    class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto"
  >
    <div class="relative h-10 min-w-full">
      <div
        class="grid grid-cols-client-table items-center h-10 v-bbgray px-4 uppercase absolute min-w-full"
      >
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          <input
            type="checkbox"
            id="checkAll"
            class="mychbox"
            @click="CheckAllClients()"
          />
          <label for="checkAll"></label>
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          №
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          Наименование
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          vip
        </div>
        <div
          v-if="role == 'Studio'"
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
        >
          <div class="btn btn-sort">Менеджер</div>
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center"
        >
          Заказов
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center"
        >
          ТС
        </div>
        <div
          class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] text-center"
        >
          Дата заказа
        </div>
      </div>
    </div>

    <div
      v-for="item in PaginatedClientList"
      :key="item"
      class="grid grid-cols-client-table px-4 py-2 v-bbgray items-center transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white max-2xl:grid-cols-all-mobile max-2xl:auto-rows-[50px]"
      :class="{
        'status-11': item.correct == 0,
        'status-6': item.correct == 1,
      }"
    >
      <div>
        <input
          type="checkbox"
          :id="item.iid"
          :value="item.id"
          class="mychbox"
          v-model="checkedIds"
        />
        <label :for="item.iid"></label>
      </div>

      <div>{{ item.iid }} {{ item.correct }}</div>

      <a
        class="flex items-center cursor-pointer group"
        @click="showClient(item)"
      >
        <div
          v-if="item.correct != 1"
          class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-7"
        >
          <svg class="h-5 w-5 fill-white">
            <use xlink:href="@/assets/i.svg#status-11"></use>
          </svg>
        </div>
        <template v-else>
          <div
            class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-6"
          >
            <IcoPic :pic="item.picture" :name="item.name" />
          </div>
        </template>
        <span
          class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
          >{{ item.name }}</span
        >
      </a>

      <div>
        <svg v-if="item.vip" class="h-5 w-5 fill-gray">
          <use xlink:href="@/assets/i.svg#ico-vip"></use>
        </svg>
      </div>
      <div v-if="role == 'Studio'">
        <div v-if="item.mname" class="flex items-center">
          <div
            class="min-w-[1.5rem] w-11 h-11 rounded-full mr-3 overflow-hidden bg-status-7"
          >
            <IcoPic :pic="item.mpicture" :name="item.msecondname" />
          </div>
          <span class="font-latobold">{{ item.msecondname }}</span>
        </div>
      </div>

      <div class="flex items-center justify-center">
        <svg class="w-5 h-5 fill-gray-400 mr-2">
          <use xlink:href="@/assets/i.svg#nav-flow"></use>
        </svg>
        <span>{{ item.countorders }}</span>
      </div>

      <div class="text-center">
        <span>{{ item.countcars }} из {{ item.ts }}</span>
        <template v-if="item.ts">
          <div
            class="mt-2 w-full flex h-[5px] rounded-full overflow-hidden bg-gray-200"
          >
            <div
              class="flex flex-col justify-center text-white w-full h-full"
              :style="'width:' + (item.countcars * 100) / item.ts + '%'"
              :class="{
                'bg-status-7': (item.countcars * 100) / item.ts < 30,
                'bg-status-10':
                  (item.countcars * 100) / item.ts > 29 &&
                  (item.countcars * 100) / item.ts < 60,
                'bg-status-1':
                  (item.countcars * 100) / item.ts > 59 &&
                  (item.countcars * 100) / item.ts < 90,
                'bg-status-5': (item.countcars * 100) / item.ts > 89,
              }"
            ></div>
          </div>
        </template>
      </div>

      <div class="text-center">
        <div v-if="item.lastorder">
          {{ moment(item.lastorder).format("DD.MM.YYYY") }}
        </div>
        <div v-else>-</div>
      </div>
    </div>
  </div>
  <div v-else-if="s.loader" class="left-0 flex-cc w-full">
    <img src="https://api.tahocrm.ru/images/loader.svg" />
  </div>
  <div
    v-else
    style="position: relative"
    class="top-128 left-0 flex-cc w-full text-slate-300 text-2xl text-center"
  >
    Нет данных
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Paginator from "@/components/ui/Paginator";

export default {
  name: "PageClient",
  components: { Paginator },

  data() {
    return {
      moment: moment,
      checkedIds: [],
      checkAll: false,
      inputActivityRangeFlag: false,
      loadingWrap: false,
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "role",
      "profile",
      "ClientList",
      "roles",
      "ClientCounter",
      "selectManagers",
      "selectMasters",
      "ccount",
      "clientPaginator",
    ]),
    page() {
      return this.clientPaginator.page;
    },
    itemsInPage() {
      return this.clientPaginator.itemsInPage;
    },
    PaginatedClientList() {
      return this.ClientList.slice(
        this.page * this.itemsInPage,
        (this.page + 1) * this.itemsInPage
      );
    },
    pageCount() {
      return Math.ceil((this.ClientList?.length ?? 0) / this.itemsInPage);
    },
  },

  methods: {
    ...mapActions([
      "getClients",
      "setClient",
      "findClient",
      "statusClient",
      "showClient",
      "addClient",
      "clearSettings",
      "setGroupManager",
      "closePopup",
      "getActiveClients",
      "loadSettings",
      "setSettings",
    ]),

    filterStatus(status) {
      this.s.status = status;
      this.statusClient(this.s);
    },

    CheckAllClients() {
      if (this.checkAll) {
        this.checkedIds = [];
        this.checkAll = false;
      } else {
        this.checkedIds = this.ClientList.map((item) => item.iid);
        this.checkAll = true;
      }
    },

    Clear() {
      this.clearSettings();
      this.LoadClients();
    },

    LoadClients() {
      let result;

      if (this.s.active_start || this.s.active_end) {
        result = this.getActiveClients(this.s);
        return result;
      } else {
        result = this.getClients();
        return result;
      }
    },
    addThisClient() {
      if (confirm("Подтвердите действие добавления клиента")) {
        this.addClient();
      }
    },
  },

  async mounted() {
    window.DEBUG_CLIENT = this;
    this.loadSettings();
    this.LoadClients();
  },
};
</script>
<style>
.grid-cols-client-table {
  grid-template-columns: 2.5rem 4rem minmax(12rem, 1fr) 3rem 18rem 9rem 8rem 9rem;
}
</style>
