<template>
    <div
        v-if="s.popup == 'task'"
        id="popnew"
        class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-max overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
        <TaskEdit @close="close" />
    </div>
    <div v-if="s.popup" @click="close" id="overlay" class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>

    <template v-if="ClientTasks">
        <div class="bg-white rounded-lg">
            <div class="p-5 flex justify-between items-center v-bbgray">
                <h3 class="text-base font-latobold">Задачи</h3>

                <a class="v-btn bg-green-500 hover:bg-green-400 ml-auto mt-2" @click="addClientTask(client.id)">
                    <svg class="fill-white w-16px h-16px mr-3 rotate-45">
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                    <span class="v-btn_text">Создать ЗАДАЧУ</span>
                </a>
            </div>

            <div class="grid grid-cols-10 px-5 py-2 v-bbgray">
                <p class="text-gray-400 uppercase text-[11px] col-span-2">ЗАДАЧА</p>
                <p class="text-gray-400 uppercase text-[11px] col-span-2">ДАТА</p>
                <p class="text-gray-400 uppercase text-[11px] col-span-3">ОТДЕЛ</p>
                <!-- <p class="text-gray-400 uppercase text-[11px] col-span-2">АВТОР</p> -->
                <p class="text-gray-400 uppercase text-[11px] col-span-2">ОТВЕТСТВЕННЫЙ</p>
            </div>

            <div
                v-for="item in ClientTasks"
                :key="item"
                class="px-5 py-2 grid grid-cols-10 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white items-center relative group"
            >
                <div class="flex items-center col-span-2" @click="showTask(item), (this.s.popup = 'task')">
                    <span
                        class="rounded-full h-11 min-w-[2.75rem] w-11 flex items-center justify-center mr-4 text-lg text-white"
                        :class="{
                            'bg-status-20': item.status == 1,
                            'bg-status-1': item.status == 2,
                            'bg-status-5': item.status == 5,
                            'bg-status-6': item.status == 6,
                        }"
                    >
                        <span class="w-5 h-5">
                            <svg v-if="item.status == 1" class="w-full h-full fill-white">
                                <use xlink:href="@/assets/i.svg#ico-edit"></use>
                            </svg>
                            <svg v-if="item.status == 2" class="w-full h-full fill-white">
                                <use xlink:href="@/assets/i.svg#status-1"></use>
                            </svg>
                            <svg v-if="item.status == 5" class="w-full h-full fill-white">
                                <use xlink:href="@/assets/i.svg#status-5"></use>
                            </svg>
                            <svg v-if="item.status == 6" class="w-full h-full fill-white">
                                <use xlink:href="@/assets/i.svg#status-8"></use>
                            </svg>
                        </span>
                    </span>
                    <span class="font-latobold text-base">№{{ item.iid }}</span>
                </div>

                <div class="flex items-center col-span-2">{{ moment(item.date).format("DD.MM.YYYY") }}</div>

                <div class="flex items-center col-span-3">
                    <div class="flex flex-col items-start">
                        <span class="text-[10px] text-gray-400 leading-none uppercase mb-1">{{ item.dep }}</span>
                        <span class="text-base font-latobold">{{ item.taskname }}</span>
                    </div>
                </div>

                <!-- <div class="flex items-center col-span-2">
                    <div
                        class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden"
                        :class="{ 'bg-status-20': item.status == 1, 'bg-status-1': item.status == 2, 'bg-status-6': item.status == 6 }"
                    >
                        <IcoPic :pic="item.wpicture" :name="item.wname" />
                    </div>
                    <div class="flex flex-col">
                        <span class="text-base font-semibold break-all max-h-10 leading-5 whitespace-pre-wrap">{{ item.wname }}</span>
                    </div>
                </div> -->

                <div class="flex items-center col-span-2">
                    <div
                        class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden"
                        :class="{ 'bg-status-20': item.status == 1, 'bg-status-1': item.status == 2, 'bg-status-6': item.status == 6 }"
                    >
                        <IcoPic :pic="item.upicture" :name="item.uname" />
                    </div>
                    <div class="flex flex-col">
                        <span class="text-base font-semibold break-all max-h-10 leading-5 whitespace-pre-wrap">{{ item.uname }}</span>
                    </div>
                </div>

                <template v-if="profile.uid == client.manager_id || ['Assistant', 'Studio'].includes(role)">
                    <div class="flex absolute top-1/2 -translate-y-1/2 right-4 opacity-0 group-hover:opacity-100 transition-all duration-150">
                        <a class="cursor-pointer ml-4" @click="delClientTask(item)">
                            <svg class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150">
                                <use xlink:href="@/assets/i.svg#ico-no"></use>
                            </svg>
                        </a>
                    </div>
                </template>
            </div>
        </div>
    </template>

    <div v-else class="flex justify-center p-5">
        <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TaskEdit from "../components/TaskEdit";
import moment from "moment";

export default {
    name: "ClientTask",

    components: { TaskEdit },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "client", "ClientTasks", "tasksTypes", "task"]),
    },

    methods: {
        ...mapActions(["Tab", "addClientTask", "showTask", "delClientTask", "getClientTabsTasks"]),

        close() {
            this.s.popup = false;
            this.task = null;
            localStorage.removeItem("task");
        },
    },
    async mounted() {
        await this.getClientTabsTasks();
    },
};
</script>
