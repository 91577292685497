<template>
    <div v-if="s.popup == 'edit'" id="popnew" class="cox100 col70 active">
        <DocEdit @close="close" />
    </div>

    <template v-if="step > 0">
        <div class="bg-white rounded-lg">
            <template v-if="step == 1">
                <div class="p-5 v-bbgray text-center">
                    <h2 class="text-lg font-latobold">Создать договор</h2>
                </div>
                <div class="p-5 flex flex-wrap justify-center">
                    <div class="w-[30%] mx-auto">
                        <h3 class="text-base font-latobold mb-4 text-center">Договоры Тахографы</h3>
                        <button
                            class="px-6 py-2 border-[1px] border-gray-700 rounded uppercase text-xs flex justify-center items-center hover:bg-sky-300 transition-colors duration-150 cursor-pointer my-1 hover:text-sky-800 w-full text-center"
                            @click="pickTemplate(101)"
                        >
                            {{ dogovor[101] }}
                        </button>
                        <button
                            class="px-6 py-2 border-[1px] border-gray-700 rounded uppercase text-xs flex justify-center items-center hover:bg-sky-300 transition-colors duration-150 cursor-pointer my-1 hover:text-sky-800 w-full text-center"
                            @click="pickTemplate(102)"
                        >
                            {{ dogovor[102] }}
                        </button>
                        <button
                            class="px-6 py-2 border-[1px] border-gray-700 rounded uppercase text-xs flex justify-center items-center hover:bg-sky-300 transition-colors duration-150 cursor-pointer my-1 hover:text-sky-800 w-full text-center"
                            @click="pickTemplate(103)"
                        >
                            {{ dogovor[103] }}
                        </button>
                    </div>
                    <div class="w-[30%] mx-auto">
                        <h3 class="text-base font-latobold mb-4 text-center">Договоры мониторинг</h3>
                        <div
                            class="px-6 py-2 border-[1px] border-gray-700 rounded uppercase text-xs flex justify-center items-center hover:bg-sky-300 transition-colors duration-150 cursor-pointer my-1 hover:text-sky-800 w-full text-center"
                            @click="pickTemplate(201)"
                        >
                            {{ dogovor[201] }}
                        </div>
                        <div
                            class="px-6 py-2 border-[1px] border-gray-700 rounded uppercase text-xs flex justify-center items-center hover:bg-sky-300 transition-colors duration-150 cursor-pointer my-1 hover:text-sky-800 w-full text-center"
                            @click="pickTemplate(202)"
                        >
                            {{ dogovor[202] }}
                        </div>
                    </div>
                    <div class="w-[30%] mx-auto">
                        <h3 class="text-base font-latobold mb-4 text-center">Договоры пропуски</h3>
                        <div
                            class="px-6 py-2 border-[1px] border-gray-700 rounded uppercase text-xs flex justify-center items-center hover:bg-sky-300 transition-colors duration-150 cursor-pointer my-1 hover:text-sky-800 w-full text-center"
                            @click="pickTemplate(301)"
                        >
                            {{ dogovor[301] }}
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="step == 2">
                <div class="p-5">
                    <h3 class="text-lg font-latobold text-center">Выберите реквизиты покупателя</h3>
                    <div v-for="item in ClientCompanies" :key="item" :id="item.iid" class="my-2">
                        <h4 class="text-gray-400 uppercase mb-1">{{ item.name }}</h4>
                        <div v-for="b in item.banks" :key="b" class="">
                            <div
                                class="grid grid-cols-[1fr_12rem_12rem_12rem] px-5 py-2 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white rounded"
                                @click="pickCompany(item, b)"
                            >
                                <div class="cell w50">
                                    <div class="flex items-center">
                                        <span
                                            class="w-11 h-11 rounded-full overflow-hidden text-lg text-white flex items-center justify-center mr-4 bg-status-1"
                                            >{{ item.name.slice(0, 1) }}</span
                                        >
                                        <span class="font-latobold text-base">{{ item.name }}</span>
                                    </div>
                                </div>
                                <div class="flex flex-col">
                                    <span class="text-gray-400 mb-1">ИНН</span>
                                    <span class="font-latobold">{{ item.inn }}</span>
                                </div>
                                <div class="cell w20 preview">
                                    <span class="text-gray-400 mb-1">Бик</span>
                                    <span class="font-latobold">{{ b.bik }}</span>
                                </div>
                                <div class="cell w20 preview">
                                    <span class="text-gray-400 mb-1">БАНК</span>
                                    <span class="font-latobold">{{ b.bankname }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="step == 3">
                <div class="p-5">
                    <h3 class="text-lg font-latobold text-center">Выберите контактное лицо (директора)</h3>
                    <div v-for="item in ClientContacts" :key="item">
                        <div
                            class="px-5 py-2 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white"
                            @click="pickContact(item)"
                        >
                            <div class="flex item-center">
                                <span
                                    class="w-11 h-11 rounded-full overflow-hidden text-lg text-white flex items-center justify-center mr-4 bg-status-1"
                                    >{{ item.name.slice(0, 1) }}</span
                                >
                                <div class="flex flex-col">
                                    <span class="text-gray-400">{{ item.position }}</span>
                                    <span class="font-latobold text-base">{{ item.secondname }} {{ item.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="step == 4">
                <div class="p-5">
                    <h3 class="text-lg font-latobold text-center">Выберите поставщика</h3>
                    <div
                        class="px-5 py-2 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-white"
                        @click="pickOOO({ id: 1, name: 'ООО ТАХОБАН' })"
                    >
                        <div class="flex items-center">
                            <span
                                class="w-11 h-11 rounded-full overflow-hidden text-lg text-white flex items-center justify-center mr-4 bg-status-1"
                                >Т</span
                            >
                            <div class="font-latobold text-base">ООО ТАХОБАН</div>
                        </div>
                    </div>
                    <div
                        class="px-5 py-2 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light"
                        @click="pickOOO({ id: 2, name: 'ООО НАВИПОРТ' })"
                    >
                        <div class="flex items-center">
                            <span
                                class="w-11 h-11 rounded-full overflow-hidden text-lg text-white flex items-center justify-center mr-4 bg-status-1"
                                >Н</span
                            >
                            <div class="font-latobold text-base">ООО НАВИПОРТ</div>
                        </div>
                    </div>
                    <div
                        class="px-5 py-2 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-white"
                        @click="pickOOO({ id: 3, name: 'ИП БЕСПАЛОВА' })"
                    >
                        <div class="flex items-center">
                            <span
                                class="w-11 h-11 rounded-full overflow-hidden text-lg text-white flex items-center justify-center mr-4 bg-status-1"
                                >И</span
                            >
                            <div class="font-latobold text-base">ИП БЕСПАЛОВА</div>
                        </div>
                    </div>
                </div>
            </template>

            <!-- Дальше данные не выводятся! -->
            <template v-if="step == 5">
                <div class="cont">
                    <h3>Проверьте данные</h3>
                    <div class="line flx-sb">
                        <div class="preview status-1 w50">
                            <span class="avatar">{{ company.name.slice(0, 1) }}</span>
                            <div><sup>ПОКУПАТЕЛЬ</sup>{{ company.name }}</div>
                        </div>
                        <div class="cell w25">ИНН: {{ company.inn }}</div>
                        <div class="cell w25">ОГРН: {{ company.ogrn }}</div>
                    </div>

                    <div class="line flx-sb">
                        <div class="preview status-1 w50">
                            <span class="avatar">{{ bank.bankname.slice(0, 1) }}</span>
                            <div><sup>РЕКВИЗИТЫ</sup>{{ bank.bankname }}</div>
                        </div>
                        <div class="cell w25">РС: {{ bank.rs }}</div>
                        <div class="cell w25">КС: {{ bank.ks }}</div>
                    </div>

                    <div class="line flx-sb">
                        <div class="preview status-1 w50">
                            <span class="avatar">{{ contact.name.slice(0, 1) }}</span>
                            <div><sup>КОНТАКТ</sup>{{ contact.name }} {{ contact.secondname }}</div>
                        </div>
                        <div class="cell w25">Телефон: {{ contact.phone }}</div>
                        <div class="cell w25">E-mail: {{ contact.email }}</div>
                    </div>

                    <div class="line flx-sb">
                        <div class="preview status-1">
                            <span class="avatar">{{ ooo.name.slice(0, 1) }}</span>
                            <div><sup>ПОСТАВЩИК</sup>{{ ooo.name }}</div>
                        </div>
                    </div>

                    <div class="line bbnone flx-sb">
                        <div class="preview status-1">
                            <span class="avatar">{{ template.name.slice(0, 1) }}</span>
                            <div><sup>ДОГОВОР</sup>{{ template.name }}</div>
                        </div>
                    </div>
                </div>

                <div class="cont flx flx-sb">
                    <a class="btn btn-blu btn-ico mr15" @click="step = 0">
                        <svg class="f-wht"><use xlink:href="@/assets/i.svg#ico-prev"></use></svg> Назад
                    </a>
                    <a class="btn btn-ico" @click="add">
                        <svg class="f-wht rotate45"><use xlink:href="@/assets/i.svg#ico-no"></use></svg> Сгенерировать
                    </a>
                </div>
            </template>
        </div>
    </template>

    <div v-if="step == 0" class="bg-white rounded-lg">
        <div class="p-5 flex justify-between items-center v-bbgray">
            <h3 class="text-base font-latobold">Договоры {{ ClientDocs?.length }}</h3>
            <template v-if="profile.uid == client.manager_id || ['Assistant', 'Studio'].includes(role)">
                <a class="v-btn bg-green-500 hover:bg-green-400 ml-auto mt-2" @click="startDoc">
                    <svg class="fill-white w-16px h-16px mr-3 rotate-45">
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                    <span class="v-btn_text">Создать</span>
                </a>
            </template>
        </div>

        <div class="px-5 py-2 v-bbgray grid grid-cols-[1fr_14rem_14rem_2rem]">
            <p class="text-xs text-gray-400">Название</p>
            <p class="text-xs text-gray-400">Дата окончания</p>
            <p class="text-xs text-gray-400">Cкачать</p>
            <p class="text-xs text-gray-400"></p>
        </div>

        <div v-for="item in ClientDocs" :key="item" class="grid grid-cols-[1fr_14rem_14rem_2rem]">
            <template v-if="item.loader">
                <div class="flex justify-center"><img src="https://api.tahocrm.ru/images/loader.svg" /></div>
            </template>
            <template v-else>
                <div class="cell w50">
                    <div class="preview status-{{ item.status }}">
                        <span class="avatar">{{ item.name.slice(0, 1) }}</span>
                        <div><sup>ДОГОВОР</sup>{{ item.name }}</div>
                    </div>
                </div>

                <div class="cell w20">{{ item.date }}</div>

                <div v-if="item.status > 100" class="cell w20">
                    <a :href="'https://api.tahocrm.ru/reports/' + item.file" download>Cкачать</a>
                </div>
                <div v-else class="cell w20">
                    <a :href="'https://api.tahocrm.ru/uploads/client/orig/' + item.file" download>Cкачать</a>
                </div>

                <template v-if="profile.uid == client.manager_id || ['Assistant', 'Studio'].includes(role)">
                    <div class="flx flx-end cell w10 hov">
                        <a class="flx mr15" @click="editDoc(item), (this.s.popup = 'edit')">
                            <svg class="f-gray"><use xlink:href="@/assets/i.svg#ico-edit"></use></svg>
                        </a>
                        <a class="flx" @click="delDogovor(item)">
                            <svg class="f-gray f-delet"><use xlink:href="@/assets/i.svg#ico-no"></use></svg>
                        </a>
                    </div>
                </template>
            </template>
        </div>

        <div class="p-5">
            <div class="flex justify-center">
                <img class="w-11" src="https://api.tahocrm.ru/images/loader.svg" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DocEdit from "../components/client/DocEdit";

export default {
    name: "ClientDoc",

    components: { DocEdit },

    data() {
        return {
            step: 0,
            template: {},
            company: {},
            bank: {},
            contact: {},
            ooo: {},
            dogovor: {
                101: "Оснащение тахографами c ндс",
                102: "Оснащение тахографами c отсрочкой с ндс",
                103: "Cервисное обслуживание тахографов отсрочкой",
                104: "Абонентское обслуживание тахографов без ндс",
                201: "Оснащение оборудованием системы мониторинга транспорта",
                202: "Абонентское обслуживание мониторинг",
                301: "Пропуски",
            },
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "client", "ClientDocs", "ClientCompanies", "ClientContacts"]),
    },

    methods: {
        ...mapActions(["Tab", "addDogovor", "editDoc", "getClientTabsDocs"]),

        startDoc() {
            this.template = null;
            this.company = null;
            this.bank = null;
            this.contact = null;
            this.step = 1;
        },

        pickTemplate(template) {
            this.template = { id: template, name: this.dogovor[template] };
            if (this.ClientCompanies.length == 1 && this.ClientCompanies[0].banks.length == 1) {
                this.company = this.ClientCompanies[0];
                this.bank = this.ClientCompanies[0].banks[0];
                this.step = 3;
            } else {
                this.step = 2;
            }
        },

        pickCompany(company, bank) {
            this.company = company;
            this.bank = bank;
            this.step = 3;
        },

        pickContact(contact) {
            this.contact = contact;
            this.step = 4;
        },

        pickOOO(ooo) {
            this.ooo = ooo;
            this.step = 5;
        },

        add() {
            let formData = new FormData();
            formData.append("iid", this.client.iid);
            formData.append("company_id", this.company.id);
            formData.append("bank_id", this.bank.id);
            formData.append("contact_id", this.contact.id);
            formData.append("ooo", this.ooo.id);
            formData.append("template_id", this.template.id);
            this.addDogovor(formData);
            this.step = 0;
            this.ClientDocs.push({ loader: 1 });
        },

        close() {
            this.s.popup = false;
            this.doc = null;
            localStorage.removeItem("doc");
        },
    },

    async mounted() {
        await this.getClientTabsDocs();
    },
};
</script>
