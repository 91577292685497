<template>
  <template v-if="ClientOrders">
    <div class="bg-white rounded-lg">
      <div class="p-5 flex justify-between items-center v-bbgray">
        <h3 class="text-base font-latobold">
          Заказы {{ ClientOrders?.length }}
        </h3>
        <!-- || (role == 'Manager' && ClientAccessIds.includes(profile.uid))"> -->
        <!-- <template v-if="['Manager', 'Studio', 'Check'].includes(role)">
          <a
            class="v-btn bg-green-500 hover:bg-green-400 ml-auto mt-2"
            @click="Add"
          >
            <svg class="fill-white w-16px h-16px mr-3 rotate-45">
              <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
            <span class="v-btn_text">Создать заказ</span>
          </a>
        </template> -->
      </div>

      <div class="grid grid-cols-4 px-5 py-2 v-bbgray">
        <p class="text-gray-400 uppercase text-[11px]">Заказ</p>
        <p class="text-gray-400 uppercase text-[11px]"></p>
        <p class="text-gray-400 uppercase text-[11px]">Дата</p>
        <p class="text-gray-400 uppercase text-[11px]"></p>

        <p class="text-gray-400 uppercase text-[11px]">Компания</p>
        <p class="text-gray-400 uppercase text-[11px]">ТС</p>
      </div>

      <template v-for="order in ClientOrders" :key="order">
        <div
          class="px-5 py-2 grid grid-cols-6 v-bbgray transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white items-center relative"
          :id="'ord' + order.iid"
          :class="'line hvr status-' + order.status"
        >
          <div class="flex items-center" @click="showOrder(order)">
            <span
              class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4 text-lg text-white"
              :class="'!bg-status-' + order.status"
            >
              <span class="w-5 h-5">
                <IcoStatus :status="parseInt(order.status)" />
              </span>
            </span>
            <span class="font-latobold text-base">№{{ order.iid }}</span>
          </div>
          <div>
            <div class="w-5 h-5" v-if="order.r_id" title="рекламация">
              <svg class="w-full h-full ml-2 fill-black">
                <use xlink:href="@/assets/i.svg#status-19"></use>
              </svg>
            </div>
          </div>
          <!--<div>
                        <template v-if="order.category == 1">
                            <template v-if="order.act == 3">
                                <div class="w-5 h-5">
                                    <svg class="w-full h-full ml-2 fill-status-6"><use xlink:href="@/assets/i.svg#status-6"></use></svg>
                                </div>
                            </template>
                            <template v-if="order.act == 4">
                                <div class="w-5 h-5">
                                    <svg class="w-full h-full ml-2 fill-status-4"><use xlink:href="@/assets/i.svg#ico-mail"></use></svg>
                                </div>
                            </template>
                            <template v-if="order.act == 7">
                                <div class="w-5 h-5">
                                    <svg class="w-full h-full ml-2 fill-status-7"><use xlink:href="@/assets/i.svg#status-7"></use></svg>
                                </div>
                            </template>
                            <template v-if="order.act == 8">
                                <div class="w-5 h-5">
                                    <svg class="w-full h-full ml-2 fill-status-8"><use xlink:href="@/assets/i.svg#status-8"></use></svg>
                                </div>
                            </template>
                        </template>

                        <template v-if="order.category == 2">
                            <template v-if="order.act == 1">
                                <div class="w-5 h-5">
                                    <svg class="w-full h-full ml-2 fill-status-1"><use xlink:href="@/assets/i.svg#sputnik"></use></svg>
                                </div>
                            </template>
                            <template v-if="order.act == 2">
                                <div class="w-5 h-5">
                                    <svg class="w-full h-full ml-2 fill-status-10"><use xlink:href="@/assets/i.svg#sputnik"></use></svg>
                                </div>
                            </template>
                            <template v-if="order.act == 8">
                                <div class="w-5 h-5">
                                    <svg class="w-full h-full ml-2 fill-status-8"><use xlink:href="@/assets/i.svg#sputnik"></use></svg>
                                </div>
                            </template>
                        </template>
                    </div>-->
          <div>
            <div v-if="order.category == 1" class="w-5 h-5">
              <svg class="w-full h-full ml-2">
                <use xlink:href="@/assets/i.svg#nav-service"></use>
              </svg>
            </div>
            <div v-if="order.category == 2" class="w-5 h-5">
              <svg class="w-full h-full ml-2">
                <use xlink:href="@/assets/i.svg#ico-time"></use>
              </svg>
            </div>
          </div>
          <div>
            <p
              class="flex flex-col"
              v-if="order.dates && order.dates.length > 0"
            >
              <span>{{
                moment(order.dates[0]?.date).format("DD.MM.YYYY")
              }}</span>
              <span
                >{{ order.dates[0]?.start.slice(0, 5) }} -
                {{ order.dates[0]?.end.slice(0, 5) }}</span
              >
            </p>
          </div>

          <div class="v-gray font-latoblod-serif">
            <span v-if="order.company" class="text-slate-600">{{
              order.company
            }}</span>
          </div>

          <div class="v-gray font-latoblod-serif">
            <span
              v-if="order.num"
              class="text-slate-600 w-auto rounded-sm v-tsnum px-2 py-1 text-base"
              >{{ order.num }}</span
            >
          </div>

          <div
            v-if="parseInt(order.status) == 8 && parseInt(order.qcheck) > 0"
            class="flex items-center absolute top-1/2 -translate-y-1/2 right-0 h-14 overflow-hidden max-w-[20%] p-3 pl-5"
          >
            <span
              class="text-white z-20 relative text-lg font-latobold"
              id="timer"
              >{{ order.qcheck }}</span
            >
            <svg class="absolute top-0 -left-8 h-full fill-status-6">
              <use xlink:href="@/assets/bg.svg#bg-timer"></use>
            </svg>
          </div>
        </div>
      </template>
    </div>
  </template>

  <div v-else class="p-5 flex justify-center">
    <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import IcoStatus from "./ui/IcoStatus.vue";

export default {
  name: "ClientOrder",

  components: { IcoStatus },

  data() {
    return {
      moment: moment,
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "client",
      "ClientOrders",
      "ClientAccessIds",
    ]),
  },

  methods: {
    ...mapActions(["Tab", "showOrder", "addOrder", "getClientTabsOrders"]),

    Add() {
      let formData = new FormData();
      formData.append("client_id", this.client.id);
      this.addOrder(formData);
    },
  },
  async mounted() {
    await this.getClientTabsOrders();
  },
};
</script>
