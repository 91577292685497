<template>
    <div id="count" class="mb-9 mt-4 grid grid-cols-order__top place-content-center gap-x-14px gap-y-24px">
        <div class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out" @click="Type(1)">
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg
                    class="z-2 relative w-10 h-10 left-1/2 -translate-x-1/2 top-6 fill-white group-hover:w-12 group-hover:h-12 transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#nav-flow"></use>
                </svg>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Заказы</span
            >
        </div>

        <div class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out" @click="Type(2)">
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg
                    class="z-2 relative w-10 h-10 left-1/2 -translate-x-1/2 top-6 fill-white group-hover:w-12 group-hover:h-12 transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#ico-timer"></use>
                </svg>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-10 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >задачи</span
            >
        </div>
        <template v-if="['Studio', 'Finance', 'Buh'].includes(role)">
            <!-- <div class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out" @click="Type(3)">
				<div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
					<svg class="z-2 relative w-10 h-10 left-1/2 -translate-x-1/2 top-6 fill-white group-hover:w-12 group-hover:h-12 transition-all duration-300"><use xlink:href="@/assets/i.svg#pay-1"></use></svg>
					<svg class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-5 group-hover:w-full group-hover:h-full transition-all duration-300"><use xlink:href="@/assets/i.svg#bg-romb"></use></svg>
				</div>
				<span class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold">продажи</span>
			</div> -->

            <div
                class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
                @click="Type(4)"
            >
                <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                    <svg
                        class="z-2 relative w-10 h-10 left-1/2 -translate-x-1/2 top-6 fill-white group-hover:w-12 group-hover:h-12 transition-all duration-300"
                    >
                        <use xlink:href="@/assets/i.svg#pay-2"></use>
                    </svg>
                    <svg
                        class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-3 group-hover:w-full group-hover:h-full transition-all duration-300"
                    >
                        <use xlink:href="@/assets/i.svg#bg-romb"></use>
                    </svg>
                </div>
                <span
                    class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                    >приход Д/с</span
                >
            </div>
        </template>
        <div
            v-if="['Logist', 'Studio'].includes(role)"
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="setCalType(5)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg
                    class="z-2 relative w-10 h-10 left-1/2 -translate-x-1/2 top-6 fill-white group-hover:w-12 group-hover:h-12 transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#nav-calendar"></use>
                </svg>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-6 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Смены</span
            >
        </div>
    </div>

    <div
        v-if="['Manager', 'Logist', 'Studio', 'Check'].includes(role) && [1, 2, 3].includes(parseInt(s.calendar))"
        class="flex flex-col items-center pt-2"
    >
        <div class="flex flex-wrap items-center mt-2">
            <select class="h-9 w-44 bg-slate-50" @change="findCalendar(s)" v-model="s.manager">
                <option value="">Ответственный</option>
                <template v-for="m in selectWorkers" :key="m">
                    <option :value="m.uid">
                        <span>{{ m.id }}</span> {{ m.secondname }} {{ m.name }}
                    </option>
                </template>
            </select>
            <button class="v-btn bg-gray-500 hover:bg-gray-400 mx-auto ml-4" v-if="s.clear">
                <span class="v-btn_text" @click="Clear">Сброс</span>
            </button>
        </div>
    </div>

    <template v-if="parseInt(s.calendar) == 5"><CalendarEdit /></template>

    <template v-else><CalendarView /> </template>

    <!-- <div id="popup" class="w-1/2 absolute z-50 right-0 opacity-0 transition-all top-[280px]"></div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CalendarView from "../components/CalendarView.vue";
import CalendarEdit from "../components/CalendarEdit.vue";

export default {
    name: "PageCalendar",

    components: { CalendarView, CalendarEdit },

    data() {
        return {
            year: "",
        };
    },
    computed: {
        ...mapGetters(["s", "roles", "profile", "role", "Calendar", "CalDates", "selectWorkers", "selectMonth"]),
    },

    methods: {
        ...mapActions(["getCal", "setCalType", "findCalendar", "clearSettings"]),

        Type(type) {
            this.setCalType(type);
            this.getCal();
        },

        Clear() {
            this.clearSettings();
            this.findCalendar(this.s);
        },
    },

    mounted() {
        this.getCal();
    },
};
</script>
